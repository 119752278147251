import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../services'; // Updated import using index.js in services folder
import './SearchResults.css'; // Import remains unchanged
import { debounce } from 'lodash'; // Import remains unchanged


export function SearchResults({ query, onClose }) {
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dropdownRef = useRef(null); // Ref for detecting clicks outside
  const defaultImage = '/default-search-image.png';


  useEffect(() => {
    const fetchResults = debounce(async (query) => {
      setError(null); // Reset error before fetching
      try {
        const { data, error } = await supabase
          .from('novels')
          .select('id, title, imageURL')
          .ilike('title', `%${query}%`)
          .order('title');

        if (error) throw error;
        setResults(data);
      } catch (err) {
        console.error("Error fetching search results:", err);
        setError("Failed to fetch search results. Please try again later.");
      }
    }, 300);

    if (query) {
      fetchResults(query);
    } else {
      setResults([]); // Clear results when the query is empty
    }

    // Cleanup function to cancel debounce on component unmount or query change
    return () => fetchResults.cancel();
  }, [query]); // Only rerun when query changes

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose(); // Close the dropdown
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleResultClick = (id) => {
    navigate(`/novel/${id}`); // Navigate to the novel page
    onClose(); // Close the dropdown after navigation
  };

  return (
    <div className="search-results-dropdown" ref={dropdownRef}>
      {error && <div className="error">Error: {error}</div>}
      {results.length > 0 ? (
        <ul className="search-results-list">
          {results.map(result => (
            <li key={result.id} className="search-result-item">
              <div className="result-link" onClick={() => handleResultClick(result.id)}>
                <div className="result-content">
                  <img 
                    src={result.imageURL || defaultImage}
                    alt={result.title}
                    className="result-image"
                    onError={(e) => e.target.src = defaultImage} // Fallback for broken images
                  />
                  <h3 className="result-title">{result.title}</h3>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        query && <p className="no-results">No results found for "{query}"</p>
      )}
    </div>
  );
}

export default SearchResults;
