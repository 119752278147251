import { supabase } from './supabaseClient';
import { handleNovelsChange, handleChaptersChange, handleAnnouncementsChange } from '../utils';

// Shared channel for other subscriptions
const channel = supabase.channel('db-changes');

// Subscribes to novels table changes
export const subscribeToNovels = (setNovels) => {
  return channel
    .on('postgres_changes', { event: '*', schema: 'public', table: 'novels' }, (payload) => {
      handleNovelsChange(payload, setNovels);
    });
};

// Subscribes to chapters table changes
export const subscribeToChapters = (setChapters) => {
  return channel
    .on('postgres_changes', { event: '*', schema: 'public', table: 'chapters' }, (payload) => {
      handleChaptersChange(payload, setChapters);
    });
};


// Subscribes to novels with specific tags
export const subscribeToTagNovels = (tag, onChange) => {
  return channel
    .on('postgres_changes', { event: '*', schema: 'public', table: 'novels' }, (payload) => {
      if (payload.new && Array.isArray(payload.new.tags)) {
        const tagsArray = payload.new.tags.map(t => t.trim().toLowerCase());
        if (tagsArray.includes(tag.toLowerCase().trim())) {
          onChange([payload.new]);
        }
      }
    });
};


// Subscribes to announcements table changes
export const subscribeToAnnouncements = (setAnnouncements) => {
  return channel
    .on('postgres_changes', { event: '*', schema: 'public', table: 'announcement' }, (payload) => {
      handleAnnouncementsChange(payload, setAnnouncements);
    });
};

// Start subscription for the shared channel
export const startSubscription = () => {
  channel.subscribe((status) => {
    if (status === 'SUBSCRIBED') {
      console.log('Successfully subscribed to database changes');
    }
  });
};

// Unsubscribe from all shared channel subscriptions
export const unsubscribeAll = () => {
  supabase.removeChannel(channel);
};
