import { useContext, useEffect } from 'react';
import { AccountContext } from '../context/AccountContext';  // Import the correct context

// Custom hook to use profile
export const useProfile = () => {
  const { profile, loading, fetchProfile, updateProfile } = useContext(AccountContext);  // Use the correct context

  useEffect(() => {
    fetchProfile(); // Fetch profile when the component using this hook mounts
  }, [fetchProfile]);  // Correct dependency

  return { profile, loading, fetchProfile, updateProfile };  // Return values from context
};

// Ensure you export `useProfile` correctly
export default useProfile;
