import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../services';
import { FaDiscord, FaEye, FaEyeSlash } from "react-icons/fa";

import './SignUpPage.css';

export function SignUpPage({ onClose, openLoginModal, contextMessage }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Add this line

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showVerificationPopup, setShowVerificationPopup] = useState(false);
  const modalRef = useRef(null);

 


  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
  
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }
  
    try {
      const { error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: 'https://chaeknovels.com/verify',  // Redirect to your verify route
        },
      });
  
      if (error) throw error;
  
      setShowVerificationPopup(true);
    } catch (err) {
      console.error('Sign-up error:', err);
      setError(err.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  

  const handleDiscordSignUp = async () => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'discord',
        options: {
          redirectTo: process.env.REACT_APP_DISCORD_REDIRECT_URI,
        },
      });
      
      if (error) {
        console.error('Error with Discord sign-up:', error.message);
        setError('Failed to sign up with Discord. Please try again.');
      }
    } catch (err) {
      console.error('Error during Discord sign-up:', err.message);
      setError('An unexpected error occurred. Please try again.');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setError(null);
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="modal">
      <div className="modal-content" ref={modalRef}>
        <h2>Create a new account</h2>
        {contextMessage && <p className="context-message">{contextMessage}</p>}
        
        <form className="signup-form" onSubmit={handleSignUp}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="signup-input"
          />
          <div className="password-input-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="signup-input password-input"
            />
            <button 
              type="button" 
              onClick={togglePasswordVisibility} 
              className="password-toggle-btn"
              aria-label={showPassword ? "Hide password" : "Show password"}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="password-input-container">
            <input
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="signup-input password-input"
            />
            <button 
              type="button" 
              onClick={toggleConfirmPasswordVisibility} 
              className="password-toggle-btn"
              aria-label={showConfirmPassword ? "Hide confirm password" : "Show confirm password"}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit" disabled={loading} className="signup-button">
            {loading ? 'Registering...' : 'Register'}
          </button>
        </form>

        <div className="login-divider">
          <span>OR</span>
        </div>
        
        <button onClick={handleDiscordSignUp} className="discord-login-button">
          <FaDiscord /> Sign up with Discord
        </button>
        
        <p className="register-prompt">
          Already have an account? <button className="switch-modal-button" onClick={() => { onClose(); openLoginModal(); }}>Login to your account</button>
        </p>
      </div>

      {showVerificationPopup && <EmailVerificationPopup onClose={() => setShowVerificationPopup(false)} />}
    </div>
  );
}

const EmailVerificationPopup = ({ onClose }) => {
  return (
    <div className="modal email-verification-modal">
      <div className="modal-content email-verification-content">
        <h2>Verify Your Email</h2>
        <p>We've sent a verification email to your inbox. Please check your email and click the verification link to complete your registration.</p>
        <button onClick={onClose} className="signup-button">Close</button>
      </div>
    </div>
  );
};

export default SignUpPage;