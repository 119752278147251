import { supabase } from './supabaseClient';
import { handleAboutPageChange } from '../utils'; // Ensure this path is correct

// Define a channel specifically for about_page changes
const aboutPageChannel = supabase.channel('about_page_changes');

// Subscribes to about_page table changes
export const AboutPageRealTimeSubscriptions = (setAboutContent) => {
  return aboutPageChannel
    .on('postgres_changes', { event: '*', schema: 'public', table: 'about_page' }, (payload) => {
      handleAboutPageChange(payload, setAboutContent);
    });
};


// Start subscription for the about_page channel
export const startAboutPageSubscription = () => {
  aboutPageChannel.subscribe((status) => {
    if (status === 'SUBSCRIBED') {
      console.log('Successfully subscribed to about_page changes');
    } else {
      console.warn('Failed to subscribe:', status);
    }
  });
};

// Unsubscribe from the about_page channel
export const unsubscribeFromAboutPage = () => {
  if (aboutPageChannel) {
    supabase.removeChannel(aboutPageChannel); // Correctly remove the specific channel
    console.log('Unsubscribed from about_page changes');
  } else {
    console.warn('No channel to unsubscribe from');
  }
};
