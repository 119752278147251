/**
 * Generate structured data (JSON-LD) for a novel.
 * @param {object} novel - The novel data.
 * @returns {object|null} - The structured data for the novel.
 */
export const generateNovelStructuredData = (novel) => {
  if (!novel) return null;

  try {
    return {
      "@context": "https://schema.org",
      "@type": "Book",
      "name": novel.title || "",
      "alternateName": novel.korean ? {
        "@value": novel.korean,
        "@language": "ko"
      } : undefined,
      "author": Array.isArray(novel.author) 
        ? novel.author.map(name => ({ "@type": "Person", "name": name })) 
        : { "@type": "Person", "name": novel.author || "Unknown" },
      "url": `https://chaeknovels.com/novel/${novel.slug || ""}`,
      "image": novel.imageURL || "",
      "description": novel.summary || "",
      "genre": Array.isArray(novel.tags) ? novel.tags : [], // Use tags directly as they're already an array
      "publisher": novel.publisher ? {
        "@type": "Organization",
        "name": novel.publisher
      } : undefined,
      "inLanguage": "en",
      "datePublished": novel.createdAt ? new Date(novel.createdAt).toISOString() : new Date().toISOString(),
      "dateModified": new Date().toISOString(),
    };
  } catch (error) {
    console.error('Error generating novel structured data:', error);
    return null;
  }
};

/**
 * Generate structured data (JSON-LD) for a chapter.
 * @param {object} novel - The parent novel data.
 * @param {object} chapterData - The chapter data.
 * @returns {object|null} - The structured data for the chapter.
 */
export const generateChapterStructuredData = (novel, chapterData) => {
  if (!novel || !chapterData) return null;

  try {
    return {
      "@context": "https://schema.org",
      "@type": "Chapter",
      "name": chapterData.title || `Chapter ${chapterData.number}`,
      "position": chapterData.number,
      "isPartOf": {
        "@type": "Book",
        "name": novel.title || "",
        "alternateName": novel.korean ? {
          "@value": novel.korean,
          "@language": "ko"
        } : undefined,
        "author": Array.isArray(novel.author) 
          ? novel.author.map(name => ({ "@type": "Person", "name": name })) 
          : { "@type": "Person", "name": novel.author || "Unknown" },
        "publisher": novel.publisher ? {
          "@type": "Organization",
          "name": novel.publisher
        } : undefined,
        "url": `https://chaeknovels.com/novel/${novel.slug || ""}`
      },
      "url": `https://chaeknovels.com/novel/${novel.slug || ""}/chapter/${chapterData.number || ""}`,
      "description": `Read the ${chapterData.title || `Chapter ${chapterData.number}`} of ${
        novel.title || ""
      }. Available now online.`,
      "inLanguage": "en",
      "readableName": chapterData.title || `Chapter ${chapterData.number}`,
      "datePublished": chapterData.uploadedDate ? new Date(chapterData.uploadedDate).toISOString() : new Date().toISOString(),
      "dateModified": new Date().toISOString(),
      "interactionStatistic": {
        "@type": "InteractionCounter",
        "interactionType": { "@type": "http://schema.org/ViewAction" },
        "userInteractionCount": chapterData.views || 0
      }
    };
  } catch (error) {
    console.error('Error generating chapter structured data:', error);
    return null;
  }
};