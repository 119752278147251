/**
 * Function to determine chapter display format
 * @param {Object} chapter - Chapter object containing number and title
 * @returns {string} - Formatted chapter title
 */
export const getFormattedChapterTitle = (chapter) => {
  if (chapter.number === 0) {
    return 'Prologue';
  }

  // Check if the title is null or undefined, and provide a fallback
  if (!chapter.title) {
    return `Chapter ${chapter.number}`; // Default to 'Chapter X' when title is null or undefined
  }

  if (chapter.title.toLowerCase() === 'epilogue') {
    return 'Epilogue';
  }

  return `Chapter ${chapter.number}: ${chapter.title}`;
};
