import { supabase } from './supabaseClient';

// Retrieves all novels from the database.
export const fetchNovels = async () => {
  const { data, error } = await supabase.from('novels').select('id, title');
  if (error) throw error;
  return data;
};

// Lists all available storage buckets and filters by those starting with "novel".
export const fetchBuckets = async () => {
  const { data, error } = await supabase.storage.listBuckets();
  if (error) throw error;

  // Filter buckets that start with 'novel'
  const filteredBuckets = data.filter((bucket) => bucket.name.startsWith('novel'));

  return filteredBuckets;
};

// Uploads or replaces a file in a specified bucket.
export const uploadFile = async (bucket, file, replace = false) => {
  const filePath = file.name;
  let data, error;

  if (replace) {
    ({ data, error } = await supabase.storage
      .from(bucket)
      .upload(filePath, file, { upsert: true }));
  } else {
    ({ data, error } = await supabase.storage
      .from(bucket)
      .upload(filePath, file));
  }

  if (error) throw error;
  
  const { data: urlData } = supabase.storage.from(bucket).getPublicUrl(filePath);
  
  // Add a version parameter to the URL
  const versionedUrl = `${urlData.publicUrl}?v=${Date.now()}`;
  
  return { ...data, publicUrl: versionedUrl };
};


// Deletes a file in a specified bucket.
export const deleteFile = async (bucket, fileName) => {
  const { error } = await supabase.storage.from(bucket).remove([`${bucket}/${fileName}`]);

  if (error) {
    console.error('Error deleting file:', error.message);
    return false; // Return false if there's an error
  }
  return true; // Return true if deletion is successful
};



// Gets the public URL for a file in storage.
export const getPublicUrl = (bucket, path) => {
  const { data, error } = supabase.storage
    .from(bucket)
    .getPublicUrl(path);

  if (error) {
    console.error('Error retrieving public URL:', error.message);
    return null;
  }

  return data.publicUrl || null;
};




// Inserts a new chapter into the database with a custom ID.
export const insertChapter = async (chapterData) => {
  const { data, error } = await supabase
    .from('chapters')
    .insert([chapterData]);  // Wrap in an array
  
  if (error) {
    console.error('Error inserting chapter:', error.message);
    throw error;
  }

  return data;
};


// Checks if a file already exists in the storage bucket.
export const checkFileExists = async (bucket, fileName) => {
  const { data, error } = await supabase.storage
    .from(bucket)
    .list('', { 
      limit: 1,
      search: fileName,
      searchOptions: { 
        matchOnly: true 
      }
    });
  
  if (error) throw error;
  return data.length > 0;
};

// Checks if a chapter already exists in the database.
export const checkChapterExists = async (novelId, number) => {
  const { data, error } = await supabase
    .from('chapters')
    .select('id, number, novelId')
    .eq('novelId', novelId)
    .eq('number', number)
    .single();
  if (error && error.code !== 'PGRST116') throw error; // Ignore "no rows found" error

  // Return the existing chapter data if it exists, otherwise null
  return data || null;
};

// Updates an existing chapter in the database.
export const updateChapter = async (id, chapterData) => {
  const { data, error } = await supabase
    .from('chapters')
    .update(chapterData)
    .eq('id', id)
    .select()
    .single();
  if (error) throw error;
  return data;
};


// Gets the maximum `id` in the chapters table.
export const getMaxChapterId = async () => {
  const { data, error } = await supabase
    .from('chapters')
    .select('id')
    .order('id', { ascending: false })
    .limit(1);

  if (error) throw error;

  // Return the max id or 0 if there are no chapters
  return data.length > 0 ? data[0].id : 0;
};
