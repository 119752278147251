import React from 'react';
import './HomePageSkeleton.css';

const HomePageSkeleton = () => {
  return (
    <div className="home-page-skeleton">
      <div className="announcement-skeleton">
        <div className="skeleton-title"></div>
        <div className="skeleton-text"></div>
      </div>
      <div className="latest-updates-skeleton">
        <div className="skeleton-header"></div>
        <div className="updates-grid-skeleton">
          {[...Array(4)].map((_, index) => (
            <div key={index} className="update-card-skeleton">
              <div className="book-image-skeleton"></div>
              <div className="update-info-skeleton">
                <div className="skeleton-title"></div>
                <div className="skeleton-text"></div>
                <div className="skeleton-text"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="novels-skeleton">
        <div className="skeleton-header"></div>
        <div className="novels-grid-skeleton">
          {[...Array(6)].map((_, index) => (
            <div key={index} className="novel-card-skeleton">
              <div className="book-image-skeleton"></div>
              <div className="novel-info-skeleton">
                <div className="skeleton-title"></div>
                <div className="skeleton-text"></div>
                <div className="skeleton-text"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePageSkeleton;