import React, { useRef, useEffect, useCallback } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';

// Define the functional component first
const DeleteConfirmationPopup = ({ onDelete, onCancel, isDeleting }) => {
  const popupRef = useRef(null);

  const handleClickOutside = useCallback((event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onCancel();
    }
  }, [onCancel]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div
      className="delete-confirm-popup"
      role="dialog"
      aria-labelledby="delete-confirm-title"
      aria-describedby="delete-confirm-description"
    >
      <div className="popup-content" ref={popupRef}>
        <FiAlertTriangle size={48} color="#ff6b6b" />
        <h4 id="delete-confirm-title">Delete Account Confirmation</h4>
        <p id="delete-confirm-description">
          Are you sure you want to delete your account? This action is irreversible, and all your data will be permanently lost.
        </p>
        <div className="buttons-container">
          <button
            className="confirm-delete-button"
            onClick={onDelete}
            disabled={isDeleting}
            aria-busy={isDeleting} // Indicate to assistive tech that the button is busy
            aria-label="Confirm account deletion"
          >
            {isDeleting ? (
              <>
                <span className="spinner"></span> {/* Add a spinner class */}
                Deleting...
              </>
            ) : (
              'Yes, Delete My Account'
            )}
          </button>
          <button
            className="cancel-delete-button"
            onClick={onCancel}
            aria-label="Cancel account deletion"
          >
            No, Keep My Account
          </button>
        </div>
      </div>
    </div>
  );
};

// Wrap the component with React.memo correctly
export default React.memo(DeleteConfirmationPopup);
