import React from 'react';
import { Link } from 'react-router-dom';
import { Heart, Coffee } from 'lucide-react';
import './Footer.css'; // Make sure this import is present

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="site-footer">
      <div className="site-footer-divider">
        <span className="site-footer-line"></span>
        <span className="site-footer-dot"></span>
        <span className="site-footer-line"></span>
      </div>
      
      <div className="site-footer-content">
        <div className="site-footer-main">
          <div className="site-footer-section">
            <h3 className="section-title">About Us</h3>
            <p>
              Chaeknovels TRANSLATED delivers high-quality translations of Korean romance novels. 
              Join our community of passionate readers exploring captivating stories.
            </p>
          </div>

          <div className="site-footer-section">
            <h3 className="section-title">Quick Links</h3>
            <nav className="footer-nav">
              <Link to="/" className="site-footer-link">Home</Link>
              <Link to="/about" className="site-footer-link">About</Link>
            </nav>
          </div>

          <div className="site-footer-section">
            <h3 className="section-title">Support Us</h3>
            <p>Help us continue providing quality translations by supporting our work.</p>
            <a
              href="https://buymeacoffee.com/arcandor"
              target="_blank"
              rel="noopener noreferrer"
              className="site-footer-bmc"
            >
              <Coffee className="bmc-icon" size={20} />
              <span>Support Chaeknovels</span>
            </a>
          </div>
        </div>

        <div className="site-footer-bottom">
          <p className="footer-credit">
            Made with <Heart size={16} className="heart-icon" /> by Chaeknovels TRANSLATED
          </p>
          <p className="footer-copyright">&copy; {currentYear} All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;