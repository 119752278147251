import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProfilePictureUploader, PasswordManager } from '../components';
import DeleteConfirmationPopup from '../pages/DeleteConfirmationPopup';
import { useAccount } from '../context/AccountContext';
import { FiTrash2 } from "react-icons/fi";
import './AccountPage.css';

function AccountPage() {
  const navigate = useNavigate();
  const { profile, loading, updating, fetchProfile, updateProfile, deleteAccount } = useAccount();
  const [formData, setFormData] = useState({ username: '', email: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (profile) {
      setFormData({ username: profile.username || '', email: profile.email || '' });
    }
  }, [profile]);

  const handleProfilePicUpload = useCallback(
    async (url) => {
      await updateProfile({ avatar_url: url });
      await fetchProfile();
    },
    [updateProfile, fetchProfile]
  );

  const onUpload = (url) => {
    handleProfilePicUpload(url);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setFormData({ username: profile.username || '', email: profile.email || '' });
  };

  const handleSave = async () => {
    const updates = {};
    if (formData.username !== profile.username) {
      updates.username = formData.username;
    }
    if (formData.email !== profile.email) {
      updates.email = formData.email;
    }
  
    await updateProfile(updates);
    setIsEditing(false);
  
    if (updates.email) {
      setMessage('Please check your email to confirm the email change.');
      setTimeout(() => setMessage(''), 5000); // Message disappears after 5 seconds
      
      // Revert to the email from the `profiles` table until the user confirms the email change
      setFormData((prevData) => ({
        ...prevData,
        email: profile.email,
      }));
    }
  };

  const handleDeleteAccount = async () => {
    await deleteAccount();
    navigate('/logout');
  };

  return (
    <div className="account-page">
      <header className="account-header">
        <ProfilePictureUploader
          userId={profile?.user_id}
          currentPic={profile?.avatar_url || null}
          onUpload={onUpload}
        />
        <h1>{profile?.username || 'User'}</h1>
      </header>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="account-content">
          {message && <div className="message">{message}</div>}
          <div className="card">
            <div className="card-header">
              <h2>Personal Information</h2>
              {isEditing ? (
                <div className="button-group">
                  <button className="save-button" onClick={handleSave} disabled={updating.email || updating.username}>
                    {updating.email || updating.username ? 'Saving...' : 'Save Changes'}
                  </button>
                  <button className="cancel-button" onClick={handleCancelEdit}>Cancel</button>
                </div>
              ) : (
                <button className="edit-button" onClick={handleEdit}>Edit</button>
              )}
            </div>
            <div className="info-item">
              <label>Username</label>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                readOnly={!isEditing}
              />
            </div>
            <div className="info-item">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                readOnly={!isEditing}
              />
            </div>
          </div>

          <div className="card">
            <h2>Security</h2>
            <PasswordManager userEmail={profile.email} /> {/* Pass the email from `profile` */}
          </div>

          <details className="danger-zone">
            <summary>Danger Zone</summary>
            <div className="danger-zone-content">
              <p>Deleting your account is permanent and cannot be undone. Please be certain.</p>
              <button className="delete-account-button" onClick={() => setShowDeleteConfirm(true)}>
                <FiTrash2 /> Delete Account
              </button>
            </div>
          </details>

          {showDeleteConfirm && (
            <DeleteConfirmationPopup
              onDelete={handleDeleteAccount}
              onCancel={() => setShowDeleteConfirm(false)}
              isDeleting={updating.deleting}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default AccountPage;
