import React, { useContext, useState } from 'react';
import { AdminContext } from '../context';
import { ChapterUploader, AnnouncementUpdater } from '../components';
import './AdminPage.css';



const AdminPage = () => {
  const { isFetching } = useContext(AdminContext);
  const [activeComponent, setActiveComponent] = useState('chapterUploader');

  if (isFetching) return <p>Loading...</p>;

  return (
    <div className="admin-page">
      <h2>Admin Dashboard</h2>
      <div className="admin-toggle">
        <button
          className={activeComponent === 'chapterUploader' ? 'active' : ''}
          onClick={() => setActiveComponent('chapterUploader')}
        >
          Chapter Uploader
        </button>
        <button
          className={activeComponent === 'announcementUpdater' ? 'active' : ''}
          onClick={() => setActiveComponent('announcementUpdater')}
        >
          Announcement Updater
        </button>
      </div>
      {activeComponent === 'chapterUploader' ? <ChapterUploader /> : <AnnouncementUpdater />}
    </div>
  );
};

export default AdminPage;