// SsoContext.js
import React, { createContext, useState, useContext, useCallback } from 'react';
import { useAuth } from './AuthContext';

const SsoContext = createContext();

export const SsoProvider = ({ children }) => {
  const [ssoToken, setSsoToken] = useState(null);
  const { user, profile } = useAuth();

  const generateSsoToken = useCallback(async () => {
    if (user && profile) {
      try {
        // Ensure we use the Supabase profile avatar_url
        const userData = JSON.stringify({
          id: user.id,
          email: user.email,
          commenterName: profile.username || user.email,
          displayName: profile.username || user.email,
          avatar: profile.avatar_url || '',  // Use profile.avatar_url directly
          isAdmin: false,
          isModerator: false,
        });

        const timestamp = Date.now();

        const response = await fetch('/api/fastcomments-sso', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userData, timestamp }),
          credentials: 'include',
        });

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

        const data = await response.json();
        setSsoToken(data);
        localStorage.setItem('ssoToken', JSON.stringify(data));
        return data;
      } catch (err) {
        console.error('Error generating SSO token:', err);
        return null;
      }
    }
    return null;
  }, [user, profile]);

  const value = { ssoToken, generateSsoToken };

  return <SsoContext.Provider value={value}>{children}</SsoContext.Provider>;
};

export const useSso = () => {
  const context = useContext(SsoContext);
  if (!context) {
    throw new Error('useSso must be used within an SsoProvider');
  }
  return context;
};