import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { renderStars, timeAgo, getFormattedChapterTitleForHomePage } from '../utils';
import { AppContext } from '../context';
import { IoEye } from "react-icons/io5";
import { FaListAlt } from "react-icons/fa";
import { useAnnouncements, useSEO } from '../hooks';
import { HomePageSkeleton, Widgets } from '../components';

import './HomePage.css';
import './announcement-styles.css';

export function HomePage() {
  const { latestUpdates, fetchLatestUpdates, novels, fetchNovels } = useContext(AppContext);
  const [genres, setGenres] = useState([]);
  const [error, setError] = useState(null);
  const [loadingNovels, setLoadingNovels] = useState(true);
  const [loadingUpdates, setLoadingUpdates] = useState(true);
  const { htmlContent, announcementTitle, loading, error: announcementError } = useAnnouncements();

  const { HelmetTags } = useSEO({
    title: 'Chaeknovels TRANSLATED | Korean Romance Novels in English',
    description: 'Read the latest Korean novels in English translation. Updated regularly with new chapters and series.',
    type: 'website',
    imageUrl: 'https://chaeknovels.com/og-image.jpg', // OpenGraph Image URL
    keywords: 'Lee Seobs Love, I Cant Wait to Eat You, romance, smut, korean novels, web novels, light novels, english translation, online reading, korean literature, novel translations, manhwa',
    structuredData: {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "Chaeknovels TRANSLATED",
      "url": "https://chaeknovels.com",
      "description": "Discover popular Korean romance web-novels like 'Lee Seob's Love' and 'I Can't Wait to Eat You' in English at Chaeknovels TRANSLATED.",
      "potentialAction": [{
        "@type": "SearchAction",
        "target": {
          "@type": "EntryPoint",
          "urlTemplate": "https://chaeknovels.com/search?q={search_term_string}"
        },
        "query-input": "required name=search_term_string"
      }],
      "mainEntity": {
        "@type": "ItemList",
        "itemListElement": novels.slice(0, 10).map((novel, index) => ({
          "@type": "ListItem",
          "position": index + 1,
          "item": {
            "@type": "Book",
            "name": novel.title,
            "alternateName": { "@value": novel.korean, "@language": "ko" },
            "author": { "@type": "Person", "name": novel.author },
            "url": `https://chaeknovels.com/novel/${novel.slug}`,
            "image": novel.imageURL,
            "description": novel.summary
          }
        }))
      }
    }
  });

  const fetchData = useCallback(() => {
    const allTags = novels.flatMap((novel) => {
      try {
        return Array.isArray(novel.tags) ? novel.tags.map((tag) => tag.trim()) : [];
      } catch (e) {
        console.error('Error parsing tags for novel:', novel.id, e);
        return [];
      }
    });
    const uniqueTags = [...new Set(allTags)];
    setGenres(uniqueTags);
  }, [novels]);

  useEffect(() => {
    const loadUpdates = async () => {
      try {
        setLoadingUpdates(true);
        await fetchLatestUpdates();
      } catch (err) {
        setError('Failed to fetch updates.');
      } finally {
        setLoadingUpdates(false);
      }
    };

    const loadNovels = async () => {
      try {
        setLoadingNovels(true);
        await fetchNovels();
      } catch (err) {
        setError('Failed to fetch novels.');
      } finally {
        setLoadingNovels(false);
      }
    };

    loadUpdates();
    loadNovels();
  }, [fetchLatestUpdates, fetchNovels]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loadingNovels || loadingUpdates) {
    return (
      <div className="loading-container">
        <HelmetTags />
        <HomePageSkeleton />
      </div>
    );
  }

  if (error) return (
    <>
      <HelmetTags />
      <div>Error: {error}</div>
    </>
  );

  return (
    <div className="home-page">
      <HelmetTags />
      
      {loading ? (
        <div className="announcement loading">
          <p>Loading announcement...</p>
        </div>
      ) : announcementError ? (
        <div className="announcement error">
          <p>Error loading announcement: {announcementError}</p>
        </div>
      ) : (htmlContent || announcementTitle) ? (
        <div className="announcement-container">
          <div className="announcement">
            {announcementTitle && <h3 className="announcement-title">{announcementTitle}</h3>}
            {htmlContent && (
              <div 
                className="announcement-content" 
                dangerouslySetInnerHTML={{ __html: htmlContent }}
              />
            )}
          </div>
        </div>
      ) : null}

      <div className="hp-container">
        <Widgets widgetsToLoad={['etsy', 'bmc']} pageStyle="hp" />
      </div>

      <section className="latest-updates">
        <h2 className="section-header">LATEST UPDATES</h2>
        <div className="updates-grid">
          {latestUpdates.length > 0 ? (
            latestUpdates.map((novel) => (
              <div key={novel.id} className="update-card">
                <Link to={`/novel/${novel.slug}`} className="book-cover">
                  <div
                    className="book-image"
                    style={{
                      backgroundImage: novel.imageURL ? `url(${novel.imageURL})` : 'none',
                      backgroundColor: 'var(--light-peach)'
                    }}>
                  </div>
                </Link>
                <div className="update-info">
                  <h3>{novel.title}</h3>
                  {novel.chapters && novel.chapters.map((chapter) => (
                    <Link
                      key={chapter.id}
                      to={`/novel/${novel.slug}/chapter/${chapter.number}`}
                      className="chapter-link"
                    >
                      <span className="chapter-number">{getFormattedChapterTitleForHomePage(chapter)}</span>
                      <span className="upload-time">{timeAgo(chapter.uploadedDate)}</span>
                    </Link>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <p>No recent updates</p>
          )}
        </div>
      </section>

      <section className="novels">
        <div className="section-header-wrapper">
          <h2>NOVELS</h2>
          <span className="novel-in-progress">translation in progress</span>
        </div>
        <div className="novels-grid">
          {novels.map((novel) => (
            <div key={novel.id} className="novel-card-wrapper">
              {/* Main card link */}
              <Link to={`/novel/${novel.slug}`} className="card-link-overlay" aria-label={`Read ${novel.title}`} />
              
              <div className="novel-card">
                <div className="book-cover">
                  <div
                    className="book-image"
                    style={{
                      backgroundImage: novel.imageURL ? `url(${novel.imageURL})` : 'none',
                      backgroundColor: 'var(--light-peach)'
                    }}
                  />
                </div>
                <div className="novel-info">
                  <h3>{novel.title}</h3>
                  <div className="rating-container">
                    {renderStars(novel.AverageRating)}
                  </div>
                  <div className="novel-stats">
                    <div className="novel-stat">
                      <IoEye /> <span>{novel.totalViews || 0}</span>
                    </div>
                    <div className="novel-stat">
                      <FaListAlt /> <span>{novel.totalChapters || 0}</span>
                    </div>
                  </div>
                  <div className="tags">
                    {Array.isArray(novel.tags) && novel.tags.map((tag, index) => (
                      <Link 
                        key={index} 
                        to={`/genre/${tag.toLowerCase().trim()}`} 
                        className="tag-item"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {tag.trim()}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

      </section>

      <section className="tags-footer">
        <h2 className="section-header small-header">Genre</h2>
        <div className="tags-list">
          {genres.map((genre, index) => (
            <div key={index} className="tag-item">
              <Link to={`/genre/${genre.toLowerCase()}`}>
                {genre}
              </Link>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default HomePage;