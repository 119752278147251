import React from 'react';
import './SkeletonScreen.css';

const SkeletonScreen = () => {
  return (
    <div className="skeleton-chapter-content">
      <div className="skeleton-chapter-title"></div>
      <div className="skeleton-paragraph"></div>
      <div className="skeleton-paragraph"></div>
      <div className="skeleton-paragraph"></div>
      <div className="skeleton-paragraph"></div>
      <div className="skeleton-paragraph"></div>
    </div>
  );
};

export default SkeletonScreen;