import React from 'react';
import './NovelPageDesktopSkeleton.css';

const NovelPageDesktopSkeleton = () => {
  return (
    <div className="novel-page-skeleton">
      <div className="novel-header-skeleton">
        <div className="book-image-skeleton"></div>
        <div className="book-info-skeleton">
          <div className="skeleton-title"></div>
          <div className="skeleton-text short"></div>
          <div className="skeleton-stats">
            <div className="skeleton-stat"></div>
            <div className="skeleton-stat"></div>
          </div>
          <div className="skeleton-rating"></div>
          <div className="skeleton-follow-button"></div>
          <div className="skeleton-ad-placeholder"></div>
        </div>
      </div>

      <div className="novel-summary-skeleton">
        <div className="skeleton-title"></div>
        <div className="skeleton-text long"></div>
        <div className="skeleton-text long"></div>
        <div className="skeleton-text short"></div>
      </div>

      <div className="chapters-list-skeleton">
        <div className="skeleton-title"></div>
        <ul>
          {[...Array(5)].map((_, index) => (
            <li key={index} className="chapter-item-skeleton">
              <div className="skeleton-chapter"></div>
              <div className="skeleton-time-ago"></div>
            </li>
          ))}
        </ul>
      </div>

      <div className="rate-novel-section-skeleton">
        <div className="skeleton-rating"></div>
      </div>

      <div className="comment-section-skeleton">
        <div className="skeleton-title"></div>
        <div className="skeleton-text long"></div>
      </div>
    </div>
  );
};

export default NovelPageDesktopSkeleton;
