import React, { useState, useEffect } from 'react';
import { ChevronUp, ChevronDown } from 'lucide-react';
import './ScrollArrows.css';

const ScrollArrows = ({ contentRef }) => {
  const [showArrows, setShowArrows] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    let timeoutId;
    const handleScroll = () => {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      
      if (st > lastScrollTop) {
        // Scrolling down
        setShowArrows(false);
      } else if (st < lastScrollTop) {
        // Scrolling up
        setShowArrows(true);
      }
      
      setLastScrollTop(st <= 0 ? 0 : st);

      // Hide arrows after 2 seconds of no scrolling
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setShowArrows(false), 2000);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, [lastScrollTop]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const scrollToBottom = () => {
    if (contentRef && contentRef.current) {
      contentRef.current.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'end' 
      });
    }
  };

  return (
    <div className={`mobile-scroll-arrows ${showArrows ? 'visible' : ''}`}>
      <button 
        className="scroll-arrow scroll-up" 
        onClick={scrollToTop}
        aria-label="Scroll to top"
      >
        <ChevronUp size={24} />
      </button>
      <button 
        className="scroll-arrow scroll-down" 
        onClick={scrollToBottom}
        aria-label="Scroll to bottom"
      >
        <ChevronDown size={24} />
      </button>
    </div>
  );
};

export default ScrollArrows;