// src/utils/timeAgo.js

export function timeAgo(date) {
  const now = new Date();
  const uploaded = new Date(date);
  const differenceInSeconds = Math.floor((now - uploaded) / 1000);

  const intervals = {
    year: 365 * 24 * 60 * 60,
    month: 30 * 24 * 60 * 60,
    day: 24 * 60 * 60,
    hour: 60 * 60,
    minute: 60,
  };

  for (const [key, value] of Object.entries(intervals)) {
    const amount = Math.floor(differenceInSeconds / value);
    if (amount >= 1) {
      return `${amount} ${key}${amount > 1 ? 's' : ''} ago`;
    }
  }

  return 'just now';
}
