import React, { useState, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { FiUser, FiImage, FiTag, FiSend, FiLoader, FiX } from "react-icons/fi";
import { useModal } from '../context/ModalContext';
import { fastCommentsApi } from '../utils/fastCommentsApi';
import ContentEditable from 'react-contenteditable';
import './Comments.css';

const CommentForm = ({
  avatar,
  user,
  profile,
  onSubmit,
  urlId,
  placeholder = "Join the discussion...",
  isReply = false,
  isSubmitting = false,
  parentId = null,
  onReplyCancel = null
}) => {
  const { handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      comment: ''
    }
  });
  
  const { openLoginModal } = useModal();
  const contentEditableRef = useRef(null);
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [html, setHtml] = useState('');

  const handleLoginClick = () => {
    openLoginModal('Login to join the discussion');
  };

  const onSubmitForm = useCallback(async (data) => {
    if (!user || !profile) {
      openLoginModal('Login to join the discussion');
      return;
    }

    try {
      const commentData = {
        urlId,
        comment: data.comment,
        commenterName: profile.username || user.displayName || 'Anonymous',
        ...(parentId && { parentId })
      };

      await onSubmit(commentData);
      setHtml('');
      reset();

      if (isReply && onReplyCancel) {
        onReplyCancel();
      }
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  }, [user, profile, urlId, parentId, onSubmit, reset, isReply, onReplyCancel, openLoginModal]);

  const handleKeyPress = useCallback(async (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
      e.preventDefault();
      if (html.trim()) {
        await handleSubmit(onSubmitForm)();
      }
    }
  }, [html, handleSubmit, onSubmitForm]);

  const handleImageUpload = useCallback(async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setIsUploading(true);
      setUploadError(null);

      const imageUrl = await fastCommentsApi.uploadImage(file);
      
      if (imageUrl) {
        const imgElement = document.createElement('img');
        imgElement.src = imageUrl;
        imgElement.alt = 'Uploaded image';
        imgElement.className = 'inline-comment-image';
        imgElement.style.maxWidth = '300px';
        
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        range.insertNode(imgElement);
        range.collapse(false);
        
        const space = document.createTextNode(' ');
        range.insertNode(space);
        range.collapse(false);
        
        const newHtml = contentEditableRef.current.innerHTML;
        setHtml(newHtml);
        setValue('comment', newHtml, { shouldValidate: true });
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      setUploadError(error.message || 'Failed to upload image');
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  }, [setValue]);

  const handlePaste = useCallback(async (evt) => {
    evt.preventDefault();
    
    const text = evt.clipboardData.getData('text/plain');
    if (text) {
      document.execCommand('insertText', false, text);
    }
    
    const items = evt.clipboardData.items;
    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        const file = item.getAsFile();
        if (file) {
          try {
            setIsUploading(true);
            setUploadError(null);
            const imageUrl = await fastCommentsApi.uploadImage(file);
            if (imageUrl) {
              const img = document.createElement('img');
              img.src = imageUrl;
              img.alt = 'Pasted image';
              img.className = 'inline-comment-image';
              img.style.maxWidth = '300px';
              
              const selection = window.getSelection();
              const range = selection.getRangeAt(0);
              range.insertNode(img);
              range.collapse(false);
              
              const newHtml = contentEditableRef.current.innerHTML;
              setHtml(newHtml);
              setValue('comment', newHtml, { shouldValidate: true });
            }
          } catch (error) {
            console.error('Error uploading pasted image:', error);
            setUploadError(error.message || 'Failed to upload pasted image');
          } finally {
            setIsUploading(false);
          }
        }
        break;
      }
    }
  }, [setValue]);

  const handleChange = useCallback((evt) => {
    const newHtml = evt.target.value;
    setHtml(newHtml);
    setValue('comment', newHtml, { shouldValidate: true });
  }, [setValue]);

  const insertSpoilerTag = useCallback(() => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const selectedText = range.toString();
    
    const spoilerText = selectedText ? 
      `<span class="spoiler-text">${selectedText}</span>` : 
      '<span class="spoiler-text"></span>';
    
    const node = document.createElement('span');
    node.innerHTML = spoilerText;
    range.deleteContents();
    range.insertNode(node);
    range.collapse(false);
    
    const newHtml = contentEditableRef.current.innerHTML;
    setHtml(newHtml);
    setValue('comment', newHtml, { shouldValidate: true });
  }, [setValue]);

  const handleCancel = useCallback(() => {
    if (onReplyCancel) {
      setHtml('');
      reset();
      onReplyCancel();
    }
  }, [onReplyCancel, reset]);

  if (!user || !profile) {
    return (
      <button
        className="comment-form-login-prompt"
        onClick={handleLoginClick}
        aria-label="Log in to comment"
      >
        Log in to comment
      </button>
    );
  }

  return (
    <div className={`comment-form ${isReply ? 'reply-form' : ''}`}>
      <div className="comment-avatar">
        {avatar ? (
          <img 
            src={avatar} 
            alt="User avatar" 
            className="avatar-image" 
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '';
              e.target.className = 'default-avatar-pic';
            }}
          />
        ) : (
          <FiUser className="default-profile-pic" />
        )}
      </div>

      <div className="comment-input-container">
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="input-wrapper">
            <ContentEditable
              innerRef={contentEditableRef}
              html={html}
              disabled={isSubmitting || isUploading}
              onChange={handleChange}
              onPaste={handlePaste}
              onKeyDown={handleKeyPress}
              className="comment-input"
              placeholder={placeholder}
              tagName="div"
            />
            
            {uploadError && (
              <div className="upload-error">
                <span>{uploadError}</span>
                <button 
                  onClick={() => setUploadError(null)}
                  className="clear-error"
                  aria-label="Clear error"
                >
                  <FiX size={16} />
                </button>
              </div>
            )}

            <div className="input-tools">
              <div className="left-tools">
                <label className={`tool-button ${isUploading ? 'loading' : ''}`} role="button">
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleImageUpload}
                    disabled={isSubmitting || isUploading}
                  />
                  {isUploading ? (
                    <FiLoader className="icon-spinner" size={18} />
                  ) : (
                    <FiImage size={18} />
                  )}
                </label>
                <button 
                  type="button" 
                  className="tool-button spoiler-button"
                  onClick={insertSpoilerTag}
                  disabled={isSubmitting || isUploading}
                >
                  <FiTag size={16} />
                  <span>Spoiler</span>
                </button>
              </div>
              <div className="right-tools">
                {isReply && (
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="tool-button cancel-button"
                    disabled={isSubmitting || isUploading}
                  >
                    Cancel
                  </button>
                )}
                <button
                  type="submit"
                  className="tool-button submit-button"
                  disabled={!html.trim() || isSubmitting || isUploading}
                  aria-label={isSubmitting ? "Submitting..." : "Submit comment"}
                >
                  {isSubmitting || isUploading ? (
                    <FiLoader className="icon-spinner" size={18} />
                  ) : (
                    <FiSend size={18} />
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CommentForm;