import { supabase } from '../services';

export const renderStars = (rating) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

  return (
    <div className="rating-result">
      <div className="stars">
        {[...Array(fullStars)].map((_, i) => (
          <span key={`full-${i}`} className="star full">★</span>
        ))}
        {hasHalfStar && <span className="star half">★</span>}
        {[...Array(emptyStars)].map((_, i) => (
          <span key={`empty-${i}`} className="star empty">☆</span>
        ))}
      </div>
      <span className="rating-value">{rating.toFixed(1)}</span>
    </div>
  );
};

export const fetchRatings = async (itemId) => {
  try {
    const { data, error } = await supabase
      .from('ratings')
      .select('rating')
      .eq('novel_id', itemId);

    if (error) throw error;

    return data.map(r => r.rating) || [];
  } catch (error) {
    console.error('Error fetching ratings:', error.message);
    return [];
  }
};

export const subscribeToRatings = (itemId, onRatingChange) => {
  const ratingChannel = supabase
    .channel('custom-all-channel')
    .on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'ratings',
        filter: `novel_id=eq.${itemId}`
      },
      (payload) => {
        onRatingChange(payload.new.rating);
      }
    )
    .subscribe();

  return ratingChannel;
};

export const updateRating = async (profileUserId, novelId, rating) => {
  try {
    const { error } = await supabase
      .from('ratings')
      .upsert(
        {
          user_id: profileUserId,
          novel_id: novelId,
          rating,
        },
        {
          onConflict: ['user_id', 'novel_id'],
          returning: 'minimal',
        }
      );

    if (error) throw error;

    return rating; // Return the new rating directly
  } catch (error) {
    console.error('Error updating rating:', error.message);
    return null;
  }
};

export const fetchUserRating = async (novelId, userId) => {
  if (!novelId || !userId) {
    console.warn("Missing novelId or userId. Returning default rating.");
    return { rating: 0, userHasRated: false };
  }

  try {
    console.log(`Fetching user rating for novelId: ${novelId} and userId: ${userId}...`);

    const { data, error } = await supabase
      .from('ratings')
      .select('rating')
      .eq('novel_id', novelId)
      .eq('user_id', userId)
      .maybeSingle();

    if (error || !data) {
      if (error) console.error('Error fetching user rating:', error);
      return { rating: 0, userHasRated: false };
    }

    return { rating: data.rating || 0, userHasRated: true };
  } catch (err) {
    console.error('Error fetching user rating:', err);
    return { rating: 0, userHasRated: false }; // Return default values in case of error.
  }
};

// Assign to a named variable before exporting as default
const starRatingUtils = {
  renderStars,
  fetchRatings,
  subscribeToRatings,
  updateRating,
  fetchUserRating,
};

// Export the named variable
export default starRatingUtils;
