import React from 'react';

const UploadConfirmation = ({ selectedNovel, selectedBucket, chapterFiles, novels, buckets, onConfirm, onCancel }) => (
  <div className="confirmation-popup">
    <div className="confirmation-content">
      <h3>Confirm Upload</h3>
      <p>
        <strong>Selected Novel:</strong>{' '}
        <span className="novel-text">
          {novels.find(novel => novel.id === Number(selectedNovel))?.title || 'No novel selected'}
        </span>
      </p>
      <p>
        <strong>Selected Bucket:</strong>{' '}
        <span className="bucket-text">
          {buckets.find(bucket => bucket.name === selectedBucket)?.name || 'No bucket selected'}
        </span>
      </p>
      <p>
        <strong>Total chapters to upload:</strong>{' '}
        <span className="chapters-text">{chapterFiles.length}</span>
      </p>
      <div className="confirmation-buttons">
        <button onClick={onConfirm} className="confirm-button">
          Yes, Upload
        </button>
        <button onClick={onCancel} className="cu-cancel-button">
          Cancel
        </button>
      </div>
    </div>
  </div>
);



export default UploadConfirmation;
