import { createClient } from '@supabase/supabase-js';

// Ensure environment variables are loaded and valid
const supabaseUrl = process.env.REACT_APP_API_URL;
const supabaseKey = process.env.REACT_APP_API_KEY;

// Debugging: Check if environment variables are loaded correctly
console.log('Initializing Supabase with URL:', supabaseUrl);

if (!supabaseUrl || !supabaseKey) {
  console.error('Supabase URL or Key is missing. Check your environment variables.');
  throw new Error('Supabase URL or Key is missing.');
}

// Initialize the Supabase client
export const supabase = createClient(supabaseUrl, supabaseKey);
