// ChapterContent.js
import React, { useEffect, useCallback, forwardRef } from 'react';
import parse from 'html-react-parser';
import { supabase } from '../services';
import { getFormattedChapterTitle } from '../utils';
import { SkeletonScreen} from '../components';


const ChapterContent = forwardRef(({ chapterData, isLoading, isFirstLoad, error }, ref) => {
  const { id: chapterId, number, title, content } = chapterData || {};

  const trackChapterView = useCallback(async () => {
    if (typeof chapterId !== 'number') {
      console.error('Invalid chapter ID type:', typeof chapterId);
      return;
    }

    try {
      const { error } = await supabase.rpc('increment_chapter_views', { chapter_id: chapterId });
      if (error) throw error;
    } catch (error) {
      console.error('Error tracking chapter view:', error.message);
    }
  }, [chapterId]);

  useEffect(() => {
    if (chapterId) {
      const sessionKey = `viewed-chapter-${chapterId}`;
      if (!sessionStorage.getItem(sessionKey)) {
        trackChapterView();
        sessionStorage.setItem(sessionKey, 'true');
      }
    }
  }, [chapterId, trackChapterView]);

  if (isLoading && isFirstLoad) {
    return <SkeletonScreen />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div ref={ref}> {/* Add ref to this div */}
      <h2 className="chapter-title">{getFormattedChapterTitle({ number, title })}</h2>
      <div className="chapter-text">{parse(content)}</div>
    </div>
  );
});

export default React.memo(ChapterContent);
