/**
 * Handles real-time changes for any entity.
 * @param {Object} payload - The payload received from the Supabase real-time subscription.
 * @param {Function} setState - The state setter function for updating the respective state.
 */
const handleRealTimeChange = (payload, setState) => {
  switch (payload.eventType) {
    case 'INSERT':
      setState((prevData) => {
        // Avoid duplicates
        if (!prevData.some((item) => item.id === payload.new.id)) {
          return [...prevData, payload.new]; // Append new item
        }
        return prevData;
      });
      break;
    case 'UPDATE':
      setState((prevData) =>
        prevData.map((item) =>
          item.id === payload.new.id ? { ...item, ...payload.new } : item
        )
      );
      break;
    case 'DELETE':
      setState((prevData) =>
        prevData.filter((item) => item.id !== payload.old.id)
      );
      break;
    default:
      break;
  }
};

// Specific handler for novels that also updates novelDetails
export const handleNovelsChange = (payload, setNovels, setNovelDetails) => {
  handleRealTimeChange(payload, (updatedNovels) => {
    setNovels(updatedNovels);

    // Update novelDetails if the updated novel exists there
    if (payload.eventType === 'UPDATE') {
      const updatedNovel = payload.new;
      
      // Update novelDetails if it contains the updated novel
      setNovelDetails((prevDetails) => {
        if (!prevDetails[updatedNovel.id]) return prevDetails; // If the novel is not in details, skip update

        return {
          ...prevDetails,
          [updatedNovel.id]: {
            ...prevDetails[updatedNovel.id],
            ...updatedNovel, // Merge the updated novel data
          },
        };
      });
    }
  });
};

// Other specific handlers for different entities
export const handleChaptersChange = (payload, setChapters) => {
  handleRealTimeChange(payload, setChapters);
};

export const handleAnnouncementsChange = (payload, setAnnouncements) => {
  handleRealTimeChange(payload, setAnnouncements);
};

export const handleAboutPageChange = (payload, setAboutPage) => {
  handleRealTimeChange(payload, setAboutPage);
};
