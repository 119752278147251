import React, { useState } from 'react';
import { supabase } from '../services';

const PasswordManager = ({ userEmail }) => {
  const [isResetting, setIsResetting] = useState(false);

  const handlePasswordReset = async () => {
    const confirmReset = window.confirm("Are you sure you want to reset your password? A reset link will be sent to your email.");
    
    if (confirmReset) {
      setIsResetting(true);
      try {
        const { error } = await supabase.auth.resetPasswordForEmail(userEmail);
        if (error) throw error;
        alert('Password reset email sent! Please check your email.');
      } catch (error) {
        console.error('Error sending password reset email:', error.message);
        alert('Failed to send reset email. Please try again.');
      } finally {
        setIsResetting(false);
      }
    }
  };

  return (
    <div className="password-manager">
      <div className="info-item">
        <label>Password</label>
        <button
          className="edit-button"
          onClick={handlePasswordReset}
          disabled={isResetting}
        >
          {isResetting ? 'Sending...' : 'Reset Password'}
        </button>
      </div>
    </div>
  );
};

export default PasswordManager;