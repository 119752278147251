import React, { useState, useEffect } from 'react';
import { useAuth } from '../context'; // Import useAuth to get user authentication state
import { useModal } from '../context'; // Import useModal to handle modal opening
import { supabase } from '../services'; // Import supabase for direct DB interactions
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faUserPlus } from '@fortawesome/free-solid-svg-icons';

export const FollowButton = React.memo(({ novelId, initialFollowersCount, className }) => {
  const { user } = useAuth(); // Get user authentication state
  const { openLoginModal } = useModal(); // Function to open login modal
  const [isFollowed, setIsFollowed] = useState(false); // Local state to manage if the novel is followed
  const [followersCount, setFollowersCount] = useState(initialFollowersCount); // Local state to manage followers count
  const [loading, setLoading] = useState(false); // Loading state to avoid duplicate actions

  // Cache key for local storage
  const cacheKey = `bookmark-${user?.id}-${novelId}`;

  // Function to get user_id from profiles table
  const getUserIdFromProfile = async (authUserId) => {
    const { data, error } = await supabase
      .from('profiles')
      .select('user_id')
      .eq('user_id', authUserId)
      .single(); // Fetch the user_id from profiles table

    if (error) {
      console.error("Error fetching user_id from profiles:", error.message);
      return null;
    }

    return data?.user_id;
  };

  // Effect to check if the novel is already bookmarked by the user
  useEffect(() => {
    const cachedBookmark = localStorage.getItem(cacheKey);
    
    if (cachedBookmark) {
      // Set state from cache if available
      setIsFollowed(JSON.parse(cachedBookmark));
    } else if (user && novelId) {
      const checkIfFollowed = async () => {
        try {
          const userId = await getUserIdFromProfile(user.id); // Fetch user_id from profiles

          if (!userId) return;

          const { data, error } = await supabase
            .from('bookmarks')
            .select('novel_id')
            .eq('user_id', userId) // Use user_id from profiles table
            .eq('novel_id', novelId);

          if (error) throw error;

          const isFollowing = data.length > 0;
          setIsFollowed(isFollowing); // Set follow state based on data
          localStorage.setItem(cacheKey, JSON.stringify(isFollowing)); // Cache the result
        } catch (error) {
          console.error('Error checking bookmark status:', error.message);
        }
      };

      checkIfFollowed();
    }
  }, [user, novelId, cacheKey]);

  // Function to fetch the updated followers count from the server
  const fetchFollowersCount = async () => {
    try {
      const { data, error } = await supabase
        .from('novels')
        .select('followers')
        .eq('id', novelId)
        .single();

      if (error) throw error;

      setFollowersCount(data.followers); // Update the followers count state with the latest value
    } catch (error) {
      console.error('Error fetching updated followers count:', error.message);
    }
  };

  // Handler to toggle bookmark (follow/unfollow) status
  const handleBookmarkToggle = async () => {
    if (!user) {
      openLoginModal(); // Open login modal if user is not logged in
      return;
    }

    setLoading(true); // Set loading to prevent multiple clicks

    try {
      const userId = await getUserIdFromProfile(user.id); // Fetch user_id from profiles

      if (!userId) {
        console.error("Error fetching user_id from profiles");
        setLoading(false);
        return;
      }

      if (isFollowed) {
        // Optimistically update UI
        setIsFollowed(false);
        setFollowersCount((prev) => Math.max(prev - 1, 0)); // Decrement count, ensuring it doesn't go below 0

        // Unfollow logic (removing the bookmark)
        const { error } = await supabase
          .from('bookmarks')
          .delete()
          .eq('user_id', userId) // Use user_id from profiles table
          .eq('novel_id', novelId);

        if (error) throw error;
        
        localStorage.setItem(cacheKey, JSON.stringify(false)); // Update cache

        // Fetch the updated followers count from the server
        await fetchFollowersCount();
      } else {
        // Optimistically update UI
        setIsFollowed(true);
        setFollowersCount((prev) => prev + 1); // Increment followers count

        // Follow logic (adding the bookmark)
        const { error } = await supabase
          .from('bookmarks')
          .insert({ user_id: userId, novel_id: novelId }); // Use user_id from profiles table

        if (error) throw error;

        localStorage.setItem(cacheKey, JSON.stringify(true)); // Update cache

        // Fetch the updated followers count from the server
        await fetchFollowersCount();
      }
    } catch (error) {
      console.error('Error toggling bookmark:', error.message);
      // Revert UI changes if an error occurs
      setIsFollowed((prev) => !prev);
      setFollowersCount((prev) => (isFollowed ? prev + 1 : Math.max(prev - 1, 0)));
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <button
      className={`${className} ${isFollowed ? 'green-gradient' : ''}`} // Apply className passed as a prop
      onClick={handleBookmarkToggle} // Handle click to toggle bookmark
      disabled={loading} // Disable button if loading
    >
      <FontAwesomeIcon icon={faBookmark} className="icon bookmark-icon" />
      <span className="follow-text">{isFollowed ? 'Followed' : 'Follow Me'}</span>
      <span className="divider">|</span>
      <FontAwesomeIcon icon={faUserPlus} className="icon userplus-icon" />
      <span className="followers-count">{followersCount}</span>
    </button>
  );
});

export default FollowButton;
