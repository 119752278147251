// NovelPage.js
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import NovelPageMobile from './NovelPageMobile';   // Corrected import path
import NovelPageDesktop from './NovelPageDesktop'; // Corrected import path

const NovelPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <>
      {isMobile ? <NovelPageMobile /> : <NovelPageDesktop />}
    </>
  );
};

export default NovelPage;
