import React, { useState, useEffect, useMemo, useCallback, useContext, Suspense } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from '../context';
import { renderStars, timeAgo, getFormattedChapterTitle, fetchUserRating, generateNovelStructuredData } from '../utils';
import { useSEO } from '../hooks';

import { useModal, AppContext } from '../context';
import { LazyImage, FollowButton, NovelPageDesktopSkeleton, Widgets } from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faBook } from '@fortawesome/free-solid-svg-icons';
import './NovelPageDesktop.css';
import parse from 'html-react-parser';
import { CommentSection } from '.';

const StarRating = React.lazy(() => import('../components/StarRating'));

function NovelPageDesktop() {
  const { slug } = useParams();
  const { user } = useAuth();
  const { fetchNovelDetails, novelDetails } = useContext(AppContext);
  const { openLoginModal } = useModal();

  const [novel, setNovel] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc');
  const [error, setError] = useState(null);
  const [userRating, setUserRating] = useState({ rating: 0, userHasRated: false });
  const [isLoading, setIsLoading] = useState(true);

  const seoData = novel
    ? {
        title: `${novel.title} - Read Online | Chaeknovels`,
        description: `Read ${novel.title} online. ${novel.summary?.substring(0, 150)}...`,
        type: 'book', // Correct type for a novel
        imageUrl: novel.imageURL,
        keywords: `${novel.title}, ${novel.author}, ${novel.tags.join(', ')}, korean novel, web novel`,
        author: novel.author,
        structuredData: generateNovelStructuredData(novel)
      }
    : {
        title: 'Loading... | Chaeknovels',
        description: 'Discover Korean novels translated to English at Chaeknovels.',
        type: 'website', // Default type during loading
      };

  const { HelmetTags } = useSEO(seoData);

  useEffect(() => {
    const fetchNovel = async () => {
      try {
        setIsLoading(true);
        const novelData = novelDetails[slug] ? novelDetails[slug] : await fetchNovelDetails(slug);
        setNovel(novelData);
      } catch (err) {
        console.error('Error fetching novel details:', err);
        setError('Failed to load novel details. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };
    if (slug) fetchNovel();
  }, [slug, novelDetails, fetchNovelDetails]);

  useEffect(() => {
    const fetchUserRatingData = async () => {
      if (user && novel?.id) {
        const userRatingData = await fetchUserRating(novel.id, user.id);
        setUserRating(userRatingData);
      }
    };
    fetchUserRatingData();
  }, [user, novel]);

  const handleRatingUpdate = useCallback(
    (newRating) => {
      setUserRating((prev) => ({ ...prev, rating: newRating, userHasRated: true }));
    },
    []
  );

  const sortedChapters = useMemo(() => {
    return novel?.chapters
      ? [...novel.chapters].sort((a, b) => (sortOrder === 'desc' ? b.number - a.number : a.number - b.number))
      : [];
  }, [novel, sortOrder]);

  const toggleSort = useCallback(() => {
    setSortOrder((prevOrder) => (prevOrder === 'desc' ? 'asc' : 'desc'));
  }, []);

  return (
    <>
      <HelmetTags />

      <div className="novel-page">
        <div className={`novel-skeleton-overlay ${isLoading ? 'visible' : 'hidden'}`}>
          <NovelPageDesktopSkeleton />
        </div>

        <div className={`novel-content ${isLoading ? 'hidden' : 'visible'}`}>
          {error && <div className="error">{error}</div>}
          {!novel && !error && <div className="error">Novel not found.</div>}

          {novel && (
            <>
              <div className="novel-header">
                <div className="book-image">
                  <LazyImage src={novel.imageURL} alt={novel.title} />
                </div>
                <div className="book-info">
                  <h1>{novel.title}</h1>
                  <p className="korean-title">{novel.korean}</p>
                  <div className="stats">
                    <div className="total-views">
                      <FontAwesomeIcon icon={faEye} className="icon" />
                      <span>{novel.totalViews} views</span>
                    </div>
                    <div className="total-chapters">
                      <FontAwesomeIcon icon={faBook} className="icon" />
                      <span>
                        {novel.chapters.length} {novel.chapters.length === 1 ? 'chapter' : 'chapters'}
                      </span>
                    </div>
                  </div>
                  <div className="rating-result">{renderStars(novel.AverageRating)}</div>
                  <FollowButton novelId={novel.id} initialFollowersCount={novel.followers} className="ndp-follow-button" />

                  <div className="npd-container">
                    <Widgets widgetsToLoad={['etsy']} pageStyle="npd" />
                  </div>
                </div>
              </div>

              <div className="novel-summary">
                <h2>SUMMARY:</h2>
                <div className="summary-content">
                  {novel.summary ? parse(novel.summary) : 'No summary available.'}
                </div>
                <div className="author-studio">
                  <p className="author">
                    <strong>AUTHOR:</strong> {novel.author}
                  </p>
                  <p className="studio">
                    <strong>PUBLISHER:</strong> {novel.publisher}
                  </p>
                </div>
                <div className="status">{novel.status ? parse(novel.status) : ''}</div>
                <div className="tags-container">
                  {novel.tags.map((tag, index) => (
                    <Link key={index} to={`/genre/${tag.toLowerCase().trim()}`} className="tag-item">
                      {tag.trim()}
                    </Link>
                  ))}
                </div>
              </div>

              <div className="chapters-list">
                <h2>
                  CHAPTERS
                  <span className="sort-icon" onClick={toggleSort}>
                    {sortOrder === 'desc' ? '↓' : '↑'}
                  </span>
                </h2>
                <div className="chapters-container">
                  <ul>
                    {sortedChapters.map((chapter) => (
                      <li key={chapter.number}>
                        <Link to={`/novel/${novel.slug}/chapter/${chapter.number}`}>
                          <span>{getFormattedChapterTitle(chapter)}</span>
                        </Link>
                        <span className="time-ago">{timeAgo(new Date(chapter.uploadedDate))}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="rate-novel-section">
                <Suspense fallback={<div>Loading Rating...</div>}>
                  <StarRating
                    itemId={novel.id}
                    itemType="novel"
                    isAuthenticated={!!user}
                    userId={user?.id}
                    openLoginModal={openLoginModal}
                    onRatingUpdate={handleRatingUpdate}
                    userHasRated={userRating.userHasRated}
                    currentRating={userRating.rating}
                  />
                </Suspense>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="comment-wrapper">
        <CommentSection novelId={slug} />


        
      </div>
    </>
  );
}

export default React.memo(NovelPageDesktop);
