import React, { useState, useEffect, useRef } from 'react';
import './LazyImage.css';

const LazyImage = ({ src, alt, placeholder, className, fallbackColor = 'var(--content-background)' }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const [hasError, setHasError] = useState(false);
  const imgRef = useRef(null);
  const hasBeenLoaded = useRef(false); // Tracks if the image has already been loaded

  useEffect(() => {
    if (!imgRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasBeenLoaded.current) {  // Only load if not already loaded
          setIsInView(true);
          observer.unobserve(entry.target);
        }
      },
      { rootMargin: '100px' }
    );

    observer.observe(imgRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleLoad = () => {
    setIsLoaded(true);
    setHasError(false);  // Ensure that error state is reset if the image loads correctly after a retry
    hasBeenLoaded.current = true;  // Mark the image as loaded
  };

  const handleError = () => {
    setHasError(true);
    setIsLoaded(false); // Ensure image is hidden when there's an error
  };

  return (
    <div 
      ref={imgRef} 
      className={`lazy-image-container ${className || ''}`}
      style={{
        backgroundColor: fallbackColor, // Ensure the fallback color is displayed until the image loads
      }}
    >
      {isInView && !hasError && !isLoaded && placeholder && (
        <img
          src={placeholder}
          alt={alt}
          className="lazy-image placeholder"
          style={{ opacity: 1 }} // Display placeholder until the main image is loaded
        />
      )}
      
      {/* Render the image only when it is loaded and there is no error */}
      {isInView && !hasError && (
        <img
          src={src}
          alt={alt}
          className={`lazy-image ${isLoaded ? 'loaded' : ''}`}
          onLoad={handleLoad}
          onError={handleError}
          style={{ opacity: isLoaded ? 1 : 0, display: isLoaded ? 'block' : 'none' }} // Hide until loaded
        />
      )}

      {/* Show error fallback if the image fails to load */}
      {isInView && hasError && (
        <div className="lazy-image-error" style={{ backgroundColor: fallbackColor }}>
          Failed to load image
        </div>
      )}
    </div>
  );
};

export default LazyImage;
