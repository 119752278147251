import { useState, useEffect } from 'react';
import { subscribeToAnnouncements, supabase } from '../services';
import { cleanHTMLContent } from '../utils'; // Import the HTML sanitizer

// Helper function to check if a string is a valid URL
const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

const useAnnouncements = () => {
  const [htmlContent, setHtmlContent] = useState(''); // State for HTML content
  const [announcementTitle, setAnnouncementTitle] = useState(''); // State for the title
  const [loading, setLoading] = useState(false); // State for loading status
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    const fetchAnnouncementsDirectly = async () => {
      try {
        const { data, error } = await supabase
          .from('announcement')
          .select('*')
          .order('created_at', { ascending: false });

        if (error) {
          console.error('Error fetching announcements:', error);
          setError('Failed to load announcements.');
          return;
        }

        if (data.length > 0) {
          const newAnnouncement = data[0];
          setAnnouncementTitle(newAnnouncement.title || '');

          if (isValidUrl(newAnnouncement.message)) {
            setLoading(true);
            const response = await fetch(newAnnouncement.message);
            const rawHtml = await response.text();
            const sanitizedHtml = cleanHTMLContent(rawHtml); // Sanitize HTML
            setHtmlContent(sanitizedHtml);
            setLoading(false);
          } else {
            const sanitizedHtml = cleanHTMLContent(newAnnouncement.message); // Sanitize inline message
            setHtmlContent(sanitizedHtml);
          }
        }
      } catch (error) {
        console.error('Error fetching announcements:', error);
        setError('Failed to load announcements.');
      }
    };

    fetchAnnouncementsDirectly();

    const announcementChannel = subscribeToAnnouncements(async (payload) => {
      const newAnnouncement = payload.new;
      setAnnouncementTitle(newAnnouncement.title || '');

      if (isValidUrl(newAnnouncement.message)) {
        setLoading(true);
        const response = await fetch(newAnnouncement.message);
        const rawHtml = await response.text();
        const sanitizedHtml = cleanHTMLContent(rawHtml);
        setHtmlContent(sanitizedHtml);
        setLoading(false);
      } else {
        const sanitizedHtml = cleanHTMLContent(newAnnouncement.message);
        setHtmlContent(sanitizedHtml);
      }
    });

    return () => {
      if (announcementChannel) {
        supabase.removeChannel(announcementChannel);
      }
    };
  }, []);

  return { htmlContent, announcementTitle, loading, error };
};

export default useAnnouncements;
