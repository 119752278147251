import React from 'react';
import './Comments.css';

const CommentSort = ({ sortBy, onSortChange }) => {
  const sortOptions = [
    { label: 'Best', value: 'best' },
    { label: 'Newest', value: 'newest' },
    { label: 'Oldest', value: 'Oldest' }

  ];

  return (
    <div className="comment-sort">
      {sortOptions.map(({ label, value }) => (
        <button
          key={value}
          className={`sort-button ${sortBy === value ? 'active' : ''}`}
          onClick={() => onSortChange(value)}
          aria-pressed={sortBy === value}
          aria-label={`Sort by ${label}`}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

CommentSort.defaultProps = {
  sortBy: 'newest',
  onSortChange: () => {}
};

export default React.memo(CommentSort);