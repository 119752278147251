import React, { useState, useCallback } from 'react';
import Comment from './Comment';
import './Comments.css';

const CommentsList = ({ 
  comments, 
  onVote, 
  onDelete, 
  onReply,
  onLoadMoreReplies, 
  urlId, 
  user, 
  profile 
}) => {
  const [expandedReplies, setExpandedReplies] = useState({});

  const handleLoadMoreReplies = useCallback((commentId) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
    if (onLoadMoreReplies) {
      onLoadMoreReplies(commentId);
    }
  }, [onLoadMoreReplies]);

  if (!comments || comments.length === 0) {
    return (
      <div className="comments-empty">
        Be the first to comment!
      </div>
    );
  }

  return (
    <div className="comments-list">
      {comments.map((comment) => (
        <React.Fragment key={comment.id}>
          <Comment
            comment={comment}
            onVote={onVote}
            onDelete={onDelete}
            onReply={onReply}
            onLoadMoreReplies={() => handleLoadMoreReplies(comment.id)}
            user={user}
            profile={profile}
            urlId={urlId}
            expandedReplies={expandedReplies[comment.id]}
          />
          {comment.totalReplies > (comment.children?.length || 0) && (
            <button
              className="load-more-replies-button"
              onClick={() => handleLoadMoreReplies(comment.id)}
            >
              {expandedReplies[comment.id] ? "Hide Replies" : `Show More Replies (${comment.totalReplies - (comment.children?.length || 0)})`}
            </button>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default React.memo(CommentsList);
