import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { fetchNovels, fetchBuckets } from '../services'; // Import functions from AdminService
import { useAuth } from './AuthContext';  // Import useAuth hook from AuthContext

export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const { isAdmin, loading: authLoading } = useAuth();  // Get admin status from AuthContext
  const [selectedNovel, setSelectedNovel] = useState('');
  const [selectedBucket, setSelectedBucket] = useState('');
  const [buckets, setBuckets] = useState([]);
  const [chapterFiles, setChapterFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState({});
  const [novels, setNovels] = useState([]);
  const [loading, setLoading] = useState(false); // Manage loading state for admin-specific tasks

  // Fetch buckets from Supabase
  const loadBuckets = useCallback(async () => {
    if (!isAdmin) return;  // Only fetch if user is an admin
    try {
      setLoading(true);
      const bucketsData = await fetchBuckets();
      setBuckets(bucketsData);
    } catch (error) {
      console.error('Error fetching buckets:', error);
    } finally {
      setLoading(false);
    }
  }, [isAdmin]);

  // Fetch novels from Supabase
  const loadNovels = useCallback(async () => {
    if (!isAdmin) return;  // Only fetch if user is an admin
    try {
      setLoading(true);
      const novelsData = await fetchNovels();
      setNovels(novelsData);
    } catch (error) {
      console.error('Error fetching novels:', error);
    } finally {
      setLoading(false);
    }
  }, [isAdmin]);

  // Fetch data on component mount
  useEffect(() => {
    if (!authLoading && isAdmin) {  // Wait until auth state is resolved
      loadBuckets();
      loadNovels();
    }
  }, [authLoading, isAdmin, loadBuckets, loadNovels]);

  // Reset state when user logs out or is no longer an admin
  const resetAdminState = () => {
    setSelectedNovel('');
    setSelectedBucket('');
    setBuckets([]);
    setChapterFiles([]);
    setUploadStatus({});
    setNovels([]);
  };

  useEffect(() => {
    if (!isAdmin) {
      resetAdminState(); // Reset if user is not admin
    }
  }, [isAdmin]);

  const contextValue = {
    selectedNovel,
    setSelectedNovel,
    selectedBucket,
    setSelectedBucket,
    buckets,
    loadBuckets,
    novels,
    loadNovels,
    chapterFiles,
    setChapterFiles,
    uploadStatus,
    setUploadStatus,
    loading,
  };

  return <AdminContext.Provider value={contextValue}>{!loading && children}</AdminContext.Provider>;
};

// Custom hook for easier access to AdminContext
export const useAdmin = () => {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error("useAdmin must be used within an AdminProvider");
  }
  return context;
};

export default AdminProvider;
