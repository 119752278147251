// components/UpdateField.js
import React from 'react';

const UpdateField = ({ label, value, onChange, onUpdate, isLoading }) => (
  <div className="update-item">
    <input type="text" placeholder={`Enter new ${label}`} value={value} onChange={onChange} />
    <button onClick={onUpdate} disabled={isLoading}>
      {isLoading ? 'Updating...' : 'Update'}
    </button>
  </div>
);

export default UpdateField;
