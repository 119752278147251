const API_BASE_URL = '/api/comments';

class FastCommentsApi {
  constructor() {
    this.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
  }

  validateUrlId(urlId) {
    if (!urlId) {
      console.error('urlId validation failed:', urlId);
      throw new Error('urlId is required');
    }
    return urlId.toString().toLowerCase().trim();
  }

  validateComment(comment) {
    if (!comment?.trim()) {
      console.error('Comment validation failed: empty comment');
      throw new Error('Comment text is required');
    }
    return comment.trim();
  }

  validateCommenterName(commenterName) {
    if (!commenterName?.trim()) {
      console.error('Commenter name validation failed: empty name');
      throw new Error('Commenter name is required');
    }
    if (typeof commenterName !== 'string') {
      console.error('Commenter name validation failed: not a string');
      throw new Error('Commenter name must be a string');
    }
    return commenterName.trim();
  }

  async handleApiResponse(response) {
    if (!response.ok) {
      let errorMessage;
      try {
        const errorData = await response.json();
        errorMessage = errorData.error || errorData.message || `HTTP error! status: ${response.status}`;
      } catch (e) {
        errorMessage = `HTTP error! status: ${response.status}`;
      }
      throw new Error(errorMessage);
    }
    return response.json();
  }

  async uploadImage(file) {
    if (!file || !(file instanceof File)) {
      throw new Error('Invalid file provided');
    }

    const validImageTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/webp',
      'image/svg+xml',
      'image/bmp',
      'image/tiff'
    ];

    if (!validImageTypes.includes(file.type)) {
      throw new Error('Please upload a valid image file (JPEG, PNG, GIF, WebP, SVG, BMP, or TIFF)');
    }

    const maxSize = 25 * 1024 * 1024;
    if (file.size > maxSize) {
      throw new Error('File size exceeds 25MB limit');
    }

    try {
      const response = await fetch(`${API_BASE_URL}?action=upload-image`, {
        method: 'POST',
        body: file,
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        credentials: 'include'
      });

      const data = await this.handleApiResponse(response);
      if (!data.url) {
        throw new Error('No image URL received from server');
      }

      return data.url;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  }

  async fetchComments(urlId, sortBy = 'newest', page = 0, parentId = null) {
    const validUrlId = this.validateUrlId(urlId);
    let order, orderBy;

    switch (sortBy) {
      case 'newest':
        order = 'DESC';
        orderBy = 'date';
        break;
      case 'oldest':
        order = 'ASC';
        orderBy = 'date';
        break;
      case 'best':
        order = 'DESC';
        orderBy = 'votes';
        break;
      default:
        order = 'DESC';
        orderBy = 'date';
    }

    try {
      const response = await fetch(`${API_BASE_URL}?action=list`, {
        method: 'POST',
        headers: this.headers,
        body: JSON.stringify({
          urlId: validUrlId,
          order,
          orderBy,
          page,
          includeCountInResponse: true,
          ...(parentId && { parentId })
        }),
        credentials: 'include'
      });

      const data = await this.handleApiResponse(response);
      return {
        comments: data.comments || [],
        count: data.count || 0,
        paging: {
          currentPage: page,
          hasNextPage: data.hasNextPage || false,
          totalPages: Math.ceil((data.count || 0) / (data.commentsPerPage || 25)) || 0
        }
      };
    } catch (error) {
      console.error('Error fetching comments:', error);
      throw error;
    }
  }

 

  async postComment({ urlId, comment, commenterName, parentId = null }) {
    this.validateUrlId(urlId);

    try {
      const commentData = {
        urlId,
        comment: comment.trim(),
        commenterName: commenterName.trim(),
        date: Date.now(),
        ...(parentId && { parentId })
      };

      const response = await fetch(`${API_BASE_URL}?action=create`, {
        method: 'POST',
        headers: this.headers,
        credentials: 'include',
        body: JSON.stringify(commentData)
      });

      return this.handleApiResponse(response);
    } catch (error) {
      console.error('Error posting comment:', error);
      throw error;
    }
  }


 

  

  async voteOnComment(commentId, direction, urlId, userId) {
    if (!commentId || !direction || !urlId || !userId) {
      throw new Error('commentId, direction, urlId, and userId are required');
    }
  

  
    try {
      const response = await fetch(`${API_BASE_URL}?action=vote`, {
        method: 'POST',
        headers: this.headers,
        credentials: 'include',
        body: JSON.stringify({
          urlId: urlId,
          commentId: commentId,
          voteId: commentId, // Using commentId as voteId as per docs
          direction: direction,
          userId: userId  // Add userId to body
 

        })
      });
  
      return this.handleApiResponse(response);
    } catch (error) {
      console.error('Error voting on comment:', error);
      throw error;
    }
  }

  async deleteVote(commentId, urlId, userId) {
    if (!commentId || !urlId || !userId) {
      throw new Error('commentId, urlId, and userId are required');
    }
  
    try {
      const response = await fetch(`${API_BASE_URL}?action=delete-vote`, {
        method: 'POST',
        headers: this.headers,
        credentials: 'include',
        body: JSON.stringify({
          urlId,
          commentId,
          userId
        })
      });
  
      return this.handleApiResponse(response);
    } catch (error) {
      console.error('Error deleting vote:', error);
      throw error;
    }
  }
  
  

  async deleteComment(commentId) {
    if (!commentId) {
      throw new Error('commentId is required');
    }

    try {
      const response = await fetch(`${API_BASE_URL}?action=delete`, {
        method: 'POST',
        headers: this.headers,
        credentials: 'include',
        body: JSON.stringify({ commentId })
      });

      return this.handleApiResponse(response);
    } catch (error) {
      console.error('Error deleting comment:', error);
      throw error;
    }
  }

  
}

export const fastCommentsApi = new FastCommentsApi();