import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../context';
import { useModal } from '../context';
import { CommentHeader, CommentsList, CommentForm, CommentSort } from '../components';
import { fastCommentsApi } from '../utils/fastCommentsApi';
import '../components/Comments.css';

const CommentSection = () => {
  const { slug, chapterNumber } = useParams();
  const { user, profile } = useAuth();
  const { openLoginModal } = useModal();
  const [comments, setComments] = useState([]);
  const [commentCount, setCommentCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('newest');
  const [currentPage, setCurrentPage] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasMoreComments, setHasMoreComments] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const urlId = useMemo(() => {
    if (!slug) {
      console.error('No slug available in route params:', { slug, chapterNumber });
      return null;
    }
    const formattedSlug = slug.toLowerCase().replace(/\s+/g, '-');
    return chapterNumber
      ? `novel-${formattedSlug}-chapter-${chapterNumber}`
      : `novel-${formattedSlug}`;
  }, [slug, chapterNumber]);

  const organizeComments = useCallback((flatComments) => {
    if (!Array.isArray(flatComments)) {
      console.error('Invalid comments data:', flatComments);
      return [];
    }

    const commentMap = new Map();
    const rootComments = [];

    flatComments.forEach((comment) => {
      if (comment?.id) {
        commentMap.set(comment.id, {
          ...comment,
          children: [],
          totalReplies: comment.totalReplies || 0
        });
      }
    });

    flatComments.forEach((comment) => {
      if (comment?.id) {
        const commentObj = commentMap.get(comment.id);
        if (comment.parentId && commentMap.has(comment.parentId)) {
          const parent = commentMap.get(comment.parentId);
          parent.children.push(commentObj);
        } else {
          rootComments.push(commentObj);
        }
      }
    });

    return rootComments;
  }, []);

  const fetchComments = useCallback(async (reset = false, page = 0) => {
    if (!urlId) {
      console.error('Cannot fetch comments: No urlId available');
      setError('Unable to load comments: Invalid identifier');
      setLoading(false);
      return;
    }

    try {
      reset ? setLoading(true) : setIsLoadingMore(true);
      setError(null);

      const data = await fastCommentsApi.fetchComments(urlId, sortBy, page);
      
      if (!data) throw new Error('No data received from API');

      const organizedComments = organizeComments(data.comments);

      setComments(prevComments => 
        reset ? organizedComments : [...prevComments, ...organizedComments]
      );
      
      setHasMoreComments(data.paging.hasNextPage);
      setTotalPages(data.paging.totalPages);
      setCurrentPage(data.paging.currentPage);
      
      // Set comment count based on the number of comments retrieved
      setCommentCount(data.comments.length);

    } catch (err) {
      console.error('Error fetching comments:', err);
      setError('Failed to load comments. Please try again later.');
    } finally {
      setLoading(false);
      setIsLoadingMore(false);
    }
  }, [urlId, sortBy, organizeComments]);

  useEffect(() => {
    if (urlId) {
      fetchComments(true, 0);
    }
  }, [fetchComments, urlId]);

  const handleLoadMore = useCallback(() => {
    if (!isLoadingMore && hasMoreComments) {
      fetchComments(false, currentPage + 1);
    }
  }, [fetchComments, currentPage, isLoadingMore, hasMoreComments]);

  const handleSubmitComment = async (commentData) => {
    if (!urlId) {
      console.error('Missing urlId in handleSubmitComment');
      return;
    }

    if (!user || !profile) {
      openLoginModal('Login to join the discussion');
      return;
    }

    try {
      setIsSubmitting(true);
      setError(null);

      const response = await fastCommentsApi.postComment({
        ...commentData,
        urlId,
        commenterName: profile.username || user.displayName || 'Anonymous'
      });

      if (response) {
        // Refetch comments to get updated count and list
        fetchComments(true, 0);
      }
    } catch (err) {
      console.error('Error posting comment:', err);
      setError('Failed to post comment. Please try again.');
      throw err;
    } finally {
      setIsSubmitting(false);
    }
  };




  const handleVote = async (commentId, direction) => {
    if (!user || !profile) {
      openLoginModal('Login to vote on comments');
      return;
    }

    try {
      setError(null);
      const comment = comments.flat().find(c => c.id === commentId);
      
      // If user is clicking same direction they previously voted, delete the vote
      if (comment?.votedByMe === direction) {
        await fastCommentsApi.deleteVote(commentId, urlId, user.id);
        
        // Update comments state to reflect vote removal
        setComments(prevComments => {
          const updateCommentVotes = (comments) => {
            return comments.map(comment => {
              if (comment.id === commentId) {
                return {
                  ...comment,
                  votedByMe: null,
                  votesUp: direction === 'up' ? 
                    Math.max(0, (comment.votesUp || 0) - 1) : 
                    comment.votesUp,
                  votesDown: direction === 'down' ? 
                    Math.max(0, (comment.votesDown || 0) - 1) : 
                    comment.votesDown
                };
              }
              if (comment.children?.length) {
                return {
                  ...comment,
                  children: updateCommentVotes(comment.children)
                };
              }
              return comment;
            });
          };
          return updateCommentVotes(prevComments);
        });
      } else {
        // Create or change vote
        await fastCommentsApi.voteOnComment(commentId, direction, urlId, user.id);
        
        setComments(prevComments => {
          const updateCommentVotes = (comments) => {
            return comments.map(comment => {
              if (comment.id === commentId) {
                // Handle vote direction change
                const prevDirection = comment.votedByMe;
                return {
                  ...comment,
                  votedByMe: direction,
                  votesUp: direction === 'up' ? 
                    (comment.votesUp || 0) + 1 : 
                    prevDirection === 'up' ? 
                      Math.max(0, (comment.votesUp || 0) - 1) : 
                      comment.votesUp,
                  votesDown: direction === 'down' ? 
                    (comment.votesDown || 0) + 1 : 
                    prevDirection === 'down' ? 
                      Math.max(0, (comment.votesDown || 0) - 1) : 
                      comment.votesDown
                };
              }
              if (comment.children?.length) {
                return {
                  ...comment,
                  children: updateCommentVotes(comment.children)
                };
              }
              return comment;
            });
          };
          return updateCommentVotes(prevComments);
        });
      }
    } catch (err) {
      console.error('Error handling vote:', err);
      setError('Failed to process vote. Please try again.');
    }
  };

  const handleDelete = async (commentId) => {
    if (!user || !profile) {
      openLoginModal('Login to delete comments');
      return;
    }

    try {
      setError(null);
      await fastCommentsApi.deleteComment(commentId);

      // Refetch comments to get updated count and list
      fetchComments(true, 0);
    } catch (err) {
      console.error('Error deleting comment:', err);
      setError('Failed to delete comment. Please try again.');
    }
  };

  if (!urlId) {
    return (
      <div className="comments-section">
        <div className="comments-error">
          Unable to load comments: Invalid configuration
        </div>
      </div>
    );
  }

  return (
    <div className="comments-section">
      <CommentHeader commentCount={commentCount} />
      
      <CommentForm
        avatar={profile?.avatar_url}
        user={user}
        profile={profile}
        onSubmit={handleSubmitComment}
        urlId={urlId}
        isSubmitting={isSubmitting}
      />
      
      <CommentSort 
        sortBy={sortBy} 
        onSortChange={(newSort) => {
          setSortBy(newSort);
          setCurrentPage(0);
          fetchComments(true, 0);
        }} 
      />
      
      {loading && !comments.length ? (
        <div className="comments-loading">Loading comments...</div>
      ) : error ? (
        <div className="comments-error">
          <span>{error}</span>
          <button 
            onClick={() => fetchComments(true, currentPage)} 
            className="retry-button"
          >
            Try Again
          </button>
        </div>
      ) : (
        <>
          <CommentsList
            comments={comments}
            onVote={handleVote}
            onDelete={handleDelete}
            onReply={handleSubmitComment}
            user={user}
            profile={profile}
            urlId={urlId}
          />
          
          {hasMoreComments && (
            <button 
              onClick={handleLoadMore} 
              className="load-more-button"
              disabled={isLoadingMore}
            >
              {isLoadingMore ? 'Loading...' : `Load More Comments (Page ${currentPage + 1}/${totalPages})`}
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default CommentSection;