import React, { useState, useCallback, memo, useEffect } from 'react';
import { FiUser, FiThumbsUp, FiThumbsDown, FiMessageCircle, FiTrash } from 'react-icons/fi';
import { motion } from 'framer-motion';
import { useModal } from '../context/ModalContext';
import CommentForm from './CommentForm';
import './Comments.css';

const SpoilerText = ({ children }) => {
  const [isRevealed, setIsRevealed] = useState(false);

  return (
    <span 
      className={`spoiler-text ${isRevealed ? 'revealed' : ''}`}
      onClick={() => setIsRevealed(!isRevealed)}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          setIsRevealed(!isRevealed);
        }
      }}
    >
      {children}
    </span>
  );
};

const renderCommentText = (text) => {
  if (!text) return '';

  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = text;

  return Array.from(tempDiv.childNodes).map((node, index) => {
    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent;
    }

    if (node.nodeType === Node.ELEMENT_NODE) {
      if (node.tagName.toLowerCase() === 'img') {
        return (
          <img
            key={`img-${index}`}
            src={node.src}
            alt={node.alt || 'Comment image'}
            className="inline-comment-image"
            onClick={() => window.open(node.src, '_blank')}
          />
        );
      }

      if (node.className.includes('spoiler-text')) {
        return <SpoilerText key={`spoiler-${index}`}>{node.textContent}</SpoilerText>;
      }

      return node.textContent || '';
    }

    return null;
  });
};

const Comment = memo(({ 
  comment, 
  user, 
  profile,
  onVote, 
  onDelete, 
  onReply,
  urlId,
  depth = 0,
  maxDepth = 2
}) => {
  const [isReplying, setIsReplying] = useState(false);
  const { openLoginModal } = useModal();

  useEffect(() => {
    if (!comment?.id) {
      console.warn('Debug: Comment received without ID:', {
        comment,
        depth,
        isReply: depth > 0,
        timestamp: new Date().toISOString()
      });
    }
  }, [comment, depth]);

  const canDelete = useCallback(() => {
    if (!profile || !comment) return false;
    return profile.id === comment.authorId;
  }, [profile, comment]);



  const handleVoteClick = useCallback((direction) => {
    console.log('Debug: Vote click', {
      direction,
      commentId: comment?.id,
      userId: user.id,  // Fixed typo from "uer.id" to "user.id"
      urlId, // Add this since it's required by the API
      userPresent: !!user,
      profilePresent: !!profile,
      timestamp: new Date().toISOString()
    });
  
    if (!user || !profile) {
      openLoginModal('Login to vote on comments');
      return;
    }
  
    if (!comment?.id) {
      console.error('Debug: Attempted vote on invalid comment:', {
        comment,
        direction,
        stack: new Error().stack
      });
      return;
    }
  
    // Pass urlId to onVote
    onVote(comment.id, direction, urlId, user.id); // Added user.id

  }, [comment, user, profile, onVote, openLoginModal, urlId]);

  

  

  



  const handleReplyClick = useCallback(() => {
    if (!user || !profile) {
      openLoginModal('Login to reply to comments');
      return;
    }

    if (!comment?.id) {
      console.error('Debug: Attempted reply to invalid comment:', {
        comment,
        stack: new Error().stack
      });
      return;
    }

    setIsReplying(!isReplying);
  }, [comment, user, profile, isReplying, openLoginModal]);

  const handleReplySubmit = useCallback(async (replyData) => {
    if (!comment?.id) {
      console.error('Debug: Attempted to submit reply to invalid comment:', {
        comment,
        replyData,
        stack: new Error().stack
      });
      return;
    }

    try {
      await onReply({
        ...replyData,
        parentId: comment.id,
        commentData: comment // Pass full parent comment data
      });
      setIsReplying(false);
    } catch (error) {
      console.error('Error submitting reply:', error);
    }
  }, [comment, onReply]);

  const handleDelete = useCallback(() => {
    if (!comment?.id) {
      console.error('Debug: Attempted to delete invalid comment:', {
        comment,
        stack: new Error().stack
      });
      return;
    }

    onDelete(comment.id, comment); // Pass full comment data
  }, [comment, onDelete]);

 

  const formatDate = (dateString) => {
    return new Date(Number(dateString)).toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  if (!comment) return null;

  // Validation check before render
  if (!comment) {
    console.error('Debug: Null comment object received');
    return null;
  }

  if (!comment.id) {
    console.error('Debug: Comment missing ID:', comment);
    return null;
  }

  return (
    <motion.div 
      className={`comment ${depth > 0 ? 'comment-reply' : ''}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.2 }}
      data-comment-id={comment.id}
      data-comment-type={depth > 0 ? 'reply' : 'root'}
    >
      <div className="comment-main-content">
        <div className="comment-avatar">
          {comment.avatarSrc ? (
            <img 
              src={comment.avatarSrc} 
              alt={`${comment.commenterName}'s avatar`}
              className="avatar-image"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '';
                e.target.className = 'default-avatar-pic';
              }}
            />
          ) : (
            <FiUser className="default-avatar-pic" />
          )}
        </div>

        <div className="comment-content">
          <div className="comment-header">
            <div className="comment-info">
              <span className="comment-author">{comment.commenterName}</span>
              <span className="comment-time">
                {formatDate(comment.date)}
              </span>
            </div>
          </div>

          <div className="comment-text">
            {renderCommentText(comment.comment)}
          </div>

          <div className="comment-actions">
            <button 
              className={`action-button ${comment.votedByMe === 'up' ? 'voted' : ''}`}
              onClick={() => handleVoteClick('up')}
              aria-label="Upvote"
              data-testid={`upvote-button-${comment.id}`}
            >
              <FiThumbsUp className="icon" />
              <span>{comment.votesUp || 0}</span>
            </button>
            
            <button 
              className={`action-button ${comment.votedByMe === 'down' ? 'voted' : ''}`}
              onClick={() => handleVoteClick('down')}
              aria-label="Downvote"
              data-testid={`downvote-button-${comment.id}`}
            >
              <FiThumbsDown className="icon" />
              <span>{comment.votesDown || 0}</span>
            </button>
            
            {depth < maxDepth && (
              <button 
                className="action-button reply"
                onClick={handleReplyClick}
                aria-label="Reply to comment"
                data-testid={`reply-button-${comment.id}`}
              >
                <FiMessageCircle className="icon" />
                <span>Reply</span>
              </button>
            )}
            
            {canDelete() && (
              <button 
                className="action-button delete"
                onClick={handleDelete}
                aria-label="Delete comment"
                data-testid={`delete-button-${comment.id}`}
              >
                <FiTrash className="icon" />
              </button>
            )}
          </div>

          {isReplying && (
            <motion.div 
              className="reply-form-container"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
            >
              <CommentForm
                onSubmit={handleReplySubmit}
                onReplyCancel={() => setIsReplying(false)}
                user={user}
                profile={profile}
                urlId={urlId}
                isReply={true}
                parentId={comment.id}
                placeholder="Write a reply..."
              />
            </motion.div>
          )}

          {comment.children?.length > 0 && (
            <div className="replies-container">
              {comment.children.map((reply) => (
                <Comment
                  key={reply.id}
                  comment={reply}
                  user={user}
                  profile={profile}
                  onVote={onVote}
                  onDelete={onDelete}
                  onReply={onReply}
                  urlId={urlId}
                  depth={depth + 1}
                  maxDepth={maxDepth}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
});

Comment.displayName = 'Comment';

export default Comment;