import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';

// Utility function to parse the chapter number and title from the filename
const parseChapterDetails = (filename) => {
  // Updated regex to allow different formats
  const match = filename.match(/Chapter\s*(\d+)[-_ ]?([^.]+)\.html/i);
  if (match) {
    const chapterNumber = match[1];
    const chapterTitle = match[2].replace(/_/g, ' ').trim(); // Replaces underscores with spaces and trims whitespace
    return { chapterNumber, chapterTitle };
  }
  return { chapterNumber: '', chapterTitle: '' };
};

const ChapterRow = ({ index, chapterFile, handleInputChange, handleDeleteRow, uploadStatus }) => {
  const [chapterNumber, setChapterNumber] = useState('');
  const [chapterTitle, setChapterTitle] = useState('');

  // Parse the chapter details once when the file is initially loaded
  useEffect(() => {
    const { chapterNumber, chapterTitle } = parseChapterDetails(chapterFile.file.name);
  
    if (!chapterNumber || !chapterTitle) {
      console.error("Failed to parse chapter details from filename:", chapterFile.file.name);
      return;
    }

    // Set chapter number and title in parent state (ChapterUploader)
    handleInputChange(index, 'chapterNumber', chapterNumber);
    handleInputChange(index, 'chapterTitle', chapterTitle);

    // Set the parsed chapterNumber and chapterTitle locally
    setChapterNumber(chapterNumber);
    setChapterTitle(chapterTitle);
    
    // No further re-renders needed after this
  }, [chapterFile.file.name, index, handleInputChange]);

  return (
    <tr>
      <td>{chapterFile.file.name}</td>
      <td>
        <input
          type="text"
          value={chapterNumber}
          onChange={(e) => {
            const value = e.target.value.replace(/\D/g, ''); // Only allow numeric input
            setChapterNumber(value); // Update the local state
            handleInputChange(index, 'chapterNumber', value); // Notify parent of the change
          }}
        />
      </td>
      <td>
        <input
          type="text"
          value={chapterTitle}
          onChange={(e) => {
            const value = e.target.value;
            setChapterTitle(value); // Update the local state
            handleInputChange(index, 'chapterTitle', value); // Notify parent of the change
          }}
        />
      </td>
      <td>{uploadStatus[chapterFile.file.name] || 'Pending'}</td>
      <td>
        <button onClick={() => handleDeleteRow(index)} className="cu-delete-button">
          <FaTimes />
        </button>
      </td>
    </tr>
  );
};

export default React.memo(ChapterRow);
