import React, { useState, useContext, useCallback } from 'react';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { AdminContext } from '../context';
import {
  uploadFile,
  insertChapter,
  updateChapter,
  getMaxChapterId,
} from '../services';
import './ChapterUploader.css';
import { ChapterRow, UploadConfirmation, CustomAlert,  } from '../components';
import { supabase } from '../services';


const ChapterUploader = () => {
  const {
    novels,
    buckets,
    selectedNovel,
    setSelectedNovel,
    selectedBucket,
    setSelectedBucket,
    chapterFiles,
    setChapterFiles,
    uploadStatus,
    setUploadStatus,
  } = useContext(AdminContext);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleNovelChange = (e) => setSelectedNovel(e.target.value);
  const handleBucketChange = (e) => setSelectedBucket(e.target.value);

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    const newFiles = files.map((file) => ({
      file,
      chapterNumber: '',
      chapterTitle: '',
    }));
    setChapterFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const newFiles = files.map((file) => ({
      file,
      chapterNumber: '',
      chapterTitle: '',
    }));
    setChapterFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  // Memoize the handleInputChange function with setChapterFiles as a dependency
  const handleInputChange = useCallback((index, field, value) => {
    setChapterFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[index][field] = value;
      return updatedFiles;
    });
  }, [setChapterFiles]); // Include setChapterFiles in the dependency array

  const handleDeleteRow = (index) => {
    const updatedFiles = chapterFiles.filter((_, i) => i !== index);
    setChapterFiles(updatedFiles);
  };

  const handleUploadConfirmation = () => {
    if (!selectedBucket && !selectedNovel) {
      setAlertMessage('Please select both a novel and a bucket before uploading chapters.');
      return;
    }

    if (!selectedBucket) {
      setAlertMessage('Please select a bucket before uploading chapters.');
      return;
    }

    if (!selectedNovel) {
      setAlertMessage('Please select a novel before uploading chapters.');
      return;
    }

    if (chapterFiles.length === 0) {
      setAlertMessage('Please add chapters to upload.');
      return;
    }

    setShowConfirmation(true); // Show upload confirmation if everything is fine
  };

  const closeAlert = () => setAlertMessage(''); // Close the alert
  
  



  const handleUpload = async () => {
    setShowConfirmation(false);
  
    for (const chapterFile of chapterFiles) {
      try {
        console.log(`Starting upload for file: ${chapterFile.file.name}`);
        setUploadStatus((prevState) => ({
          ...prevState,
          [chapterFile.file.name]: 'Uploading',
        }));
  
        // Upload file and get public URL
        const fileData = await uploadFile(selectedBucket, chapterFile.file, true);
        console.log(`File uploaded successfully. Public URL: ${fileData.publicUrl}`);
  
        // Check if chapter exists
        const { data: existingChapter, error } = await supabase
          .from('chapters')
          .select('id, number, novelId')
          .eq('novelId', Number(selectedNovel))
          .eq('number', parseInt(chapterFile.chapterNumber, 10))
          .maybeSingle(); // Use maybeSingle to avoid error if no row is found
  
        if (error) {
          console.error('Error checking for existing chapter:', error.message);
          continue;
        }

  
        // If chapter exists, update it
        if (existingChapter) {
          const chapterData = {
            novelId: Number(selectedNovel),
            number: parseInt(chapterFile.chapterNumber, 10), // Ensure it's an integer
            title: chapterFile.chapterTitle || null,
            contentURL: fileData.publicUrl,
            LastModified: new Date().toISOString()  // Only updating LastModified
          };
          console.log(`Updating existing chapter with ID: ${existingChapter.id}`);
          await updateChapter(existingChapter.id, chapterData);
          
          setUploadStatus((prevState) => ({
            ...prevState,
            [chapterFile.file.name]: 'Updated',
          }));
        } else {
          // If no chapter exists, insert a new one
          const chapterData = {
            novelId: Number(selectedNovel),
            number: parseInt(chapterFile.chapterNumber, 10), // Ensure it's an integer
            title: chapterFile.chapterTitle || null,
            contentURL: fileData.publicUrl,
            uploadedDate: new Date().toISOString(), // Setting initial upload date
            LastModified: new Date().toISOString()  // Setting initial LastModified
          };
          console.log(`Inserting new chapter`);
          const nextId = await getMaxChapterId() + 1;
          await insertChapter({ ...chapterData, id: nextId });
          setUploadStatus((prevState) => ({
            ...prevState,
            [chapterFile.file.name]: 'Uploaded',
          }));
        }
  
        console.log(`Chapter ${chapterFile.chapterNumber} processed successfully`);
      } catch (error) {
        console.error(`Error processing file ${chapterFile.file.name}:`, error.message);
        setUploadStatus((prevState) => ({
          ...prevState,
          [chapterFile.file.name]: 'Failed',
        }));
      }
    }
  };
  

  return (
    <div className="chapter-uploader">
      <h3>Batch Upload Chapters</h3>
      <div className="selection-container">
        <div className="select-wrapper">
          <label htmlFor="novel-select">Select Novel:</label>
          <select id="novel-select" value={selectedNovel} onChange={handleNovelChange}>
            <option value="">Choose a novel...</option>
            {novels.map((novel) => (
              <option key={novel.id} value={novel.id.toString()}>{novel.title}</option>
            ))}
          </select>
        </div>

        <div className="select-wrapper">
          <label htmlFor="bucket-select">Select Bucket:</label>
          <select id="bucket-select" value={selectedBucket} onChange={handleBucketChange}>
            <option value="">Choose a bucket...</option>
            {buckets.map((bucket) => (
              <option key={bucket.name} value={bucket.name}>{bucket.name}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="file-upload-area" onDrop={handleFileDrop} onDragOver={(e) => e.preventDefault()}>
        <FaCloudUploadAlt className="upload-icon" />
        <p>Drag and drop files here or click to select files</p>
        <input
          type="file"
          multiple
          onChange={handleFileSelect}
          style={{ display: 'none' }}
          id="file-input"
        />
        <label htmlFor="file-input" className="file-input-label">
          Select Files
        </label>
      </div>

      <div className="file-list">
        <table>
          <thead>
            <tr>
              <th>Filename</th>
              <th>Chapter Number</th>
              <th>Chapter Title</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {chapterFiles.map((chapterFile, index) => (
              <ChapterRow
                key={index}
                index={index}
                chapterFile={chapterFile}
                handleInputChange={handleInputChange}
                handleDeleteRow={handleDeleteRow}
                uploadStatus={uploadStatus}
              />
            ))}
          </tbody>
        </table>
      </div>

      <button
        className="upload-button"
        onClick={handleUploadConfirmation}
      >
        Upload Chapters
      </button>
      {alertMessage && <CustomAlert message={alertMessage} onClose={closeAlert} />} {/* Show custom alert */}


      {showConfirmation && (
        <UploadConfirmation
          selectedNovel={selectedNovel}
          selectedBucket={selectedBucket}
          novels={novels}
          buckets={buckets}
          chapterFiles={chapterFiles}
          onConfirm={handleUpload}
          onCancel={() => setShowConfirmation(false)}
        />
      )}
    </div>
  );
};

export default ChapterUploader;
