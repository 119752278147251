import React, { createContext, useState, useCallback, useContext, useEffect } from 'react';
import { supabase } from '../services'; // Import your Supabase client
import { useAuth } from './AuthContext'; // Import useAuth to access the authenticated user

export const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
  const { user } = useAuth();  // Get the current authenticated user from AuthContext
  const [profile, setProfile] = useState({});  // Initialize as an empty object to avoid null checks
  const [loading, setLoading] = useState(false);  // Default to false
  const [updating, setUpdating] = useState({ username: false, email: false, deleting: false });

// Function to fetch user profile from profiles table
const fetchProfile = useCallback(async () => {
  if (!user?.id) return;  // Check if user is authenticated
  setLoading(true);
  try {
    const { data, error } = await supabase
      .from('profiles')
      .select('user_id, username, email, avatar_url')  // Include avatar_url in the fields to be selected
      .eq('user_id', user.id)
      .single();

    if (error) throw new Error(`Error fetching profile: ${error.message}`);
    setProfile(data || {});  // Fallback to an empty object if no data
  } catch (error) {
    console.error('Error fetching profile:', error.message);
  } finally {
    setLoading(false);  // Ensure loading is set to false after operation
  }
}, [user?.id]);  // Dependency on user.id


  // Automatically fetch profile when user changes
  useEffect(() => {
    if (user?.id) {
      fetchProfile();
    }
  }, [user?.id, fetchProfile]);  // Dependency on user.id and fetchProfile

  // Function to update user profile in auth.users (email) and profiles table (username)
  const updateProfile = useCallback(async (updates) => {
    if (!user?.id || !updates) return;  // Check for valid input and authenticated user

    const updateKey = updates.email ? 'email' : 'username';
    setUpdating((prev) => ({ ...prev, [updateKey]: true }));

    try {
      // Update the email in auth.users only
      if (updates.email) {
        // Step 1: Update the email in auth.users using Supabase API
        const { error: authError } = await supabase.auth.updateUser({ email: updates.email });
        if (authError) throw new Error(`Error updating email in auth: ${authError.message}`);

        // No need to update the `profiles` table for email changes if synced automatically
      }

      // Update the username in the profiles table only
      if (updates.username) {
        const { error: profileError } = await supabase
          .from('profiles')
          .update({ username: updates.username })
          .eq('user_id', user.id);
        if (profileError) throw new Error(`Error updating username in profiles: ${profileError.message}`);
      }

      // Refresh profile after update
      await fetchProfile();
    } catch (error) {
      console.error('Error updating profile:', error.message);
    } finally {
      setUpdating((prev) => ({ ...prev, [updateKey]: false }));
    }
  }, [user?.id, fetchProfile]);  // Dependency on user.id and fetchProfile

  // Function to delete user account
  const deleteAccount = useCallback(async () => {
    if (!user?.id) return;  // Ensure user is authenticated
    setUpdating((prev) => ({ ...prev, deleting: true }));
    try {
      // Step 1: Delete the user in auth.users using Supabase Admin API
      const { error: deleteUserError } = await supabase.auth.admin.deleteUser(user.id);
      if (deleteUserError) throw new Error(`Error deleting user in auth: ${deleteUserError.message}`);

      // Step 2: Delete the user profile in profiles table
      const { error: profileDeleteError } = await supabase
        .from('profiles')
        .delete()
        .eq('user_id', user.id);
      if (profileDeleteError) throw new Error(`Error deleting profile: ${profileDeleteError.message}`);

      console.log('User deleted successfully from both auth.users and profiles.');
    } catch (error) {
      console.error('Error deleting user account:', error.message);
    } finally {
      setUpdating((prev) => ({ ...prev, deleting: false }));
    }
  }, [user?.id]);  // Dependency on user.id

  return (
    <AccountContext.Provider
      value={{
        profile,
        loading,
        updating,
        fetchProfile,
        updateProfile,
        deleteAccount,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

// Custom hook for easier access to AccountContext
export const useAccount = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error('useAccount must be used within an AccountProvider');
  }
  return context;
};

export default AccountProvider;
