// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';
import { Navbar, ModalManager, Footer } from './components';
import {
  LoginPage,
  SearchResults,
  HomePage,
  NovelPage,
  ChapterPage,
  AccountPage,
  Bookmarks,
  TagResults,
  AboutPage,
  AdminPage,
} from './pages';
import {
  ModalProvider,
  AppProvider,
  AuthProvider,
  useAuth,
  SsoProvider,
  AboutPageProvider,
  AccountProvider,
} from './context';
import { AdminProvider } from './context/AdminContext';

function App() {
  const location = useLocation(); // Get the current location

  // Conditionally render the footer based on the current path
  const hideFooter = ["/admin", "/account"].includes(location.pathname);


  return (
    <div className="App">
      <Navbar />
      <ModalManager />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/account" element={<ProtectedRoute component={AccountPage} />} />
        <Route path="/bookmarks" element={<ProtectedRoute component={Bookmarks} />} />
        <Route path="/novel/:slug" element={<NovelPage />} />
        <Route path="/novel/:slug/chapter/:chapterNumber" element={<ChapterPage />} />
        <Route path="/search" element={<SearchResults />} />
        <Route path="/genre/:tag" element={<TagResults />} />

        <Route
          path="/about"
          element={
            <AboutPageProvider>
              <AboutPage />
            </AboutPageProvider>
          }
        />

        <Route
          path="/admin"
          element={<ProtectedAdminRoute component={AdminPage} />}
        />
      </Routes>
      {!hideFooter && <Footer />} {/* Conditionally render the footer */}
    </div>
  );
}

function ProtectedRoute({ component: Component }) {
  const { user, loading, isAdmin } = useAuth();

  if (loading) return <div>Loading...</div>;

  return user || isAdmin ? <Component /> : <Navigate to="/login" />;
}

function ProtectedAdminRoute({ component: Component }) {
  const { isAdmin, loading } = useAuth();

  if (loading) return <div>Loading...</div>;

  return isAdmin ? (
    <AdminProvider>
      <Component />
    </AdminProvider>
  ) : (
    <Navigate to="/" />
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <HelmetProvider>
        <AuthProvider>
          <SsoProvider>
            <AccountProvider>
              <AppProvider>
                <ModalProvider>
                  <App />
                </ModalProvider>
              </AppProvider>
            </AccountProvider>
          </SsoProvider>
        </AuthProvider>
      </HelmetProvider>
    </Router>
  );
}
