/**
 * Function to determine chapter display format for the HomePage
 * @param {Object} chapter - Chapter object containing number and title
 * @returns {string} - Formatted chapter title
 */
export const getFormattedChapterTitleForHomePage = (chapter) => {
  // Check if title is null or undefined before checking its value
  const title = chapter.title ? chapter.title.toLowerCase() : '';

  if (chapter.number === 0 || title === 'prologue') {
    return 'Prologue';
  }

  if (title === 'epilogue') {
    return 'Epilogue';
  }

  return `Chapter ${chapter.number}`; // Display "Chapter X" without any title
};
