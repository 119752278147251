// Widgets.js
import './Widgets.css';
import React from 'react';

const Widgets = ({ widgetsToLoad }) => {
  const renderWidget = (widgetName) => {
    switch (widgetName) {
      case 'etsy':
        return (
          <div className="widget etsy-widget">
            <a
              href="https://arcaneaesthetica.etsy.com/listing/1811480643/beauty-in-bloom"
              target="_blank"
              rel="noopener noreferrer"
              className="widget-link"
            >
              <img
                src="/etsy-widget.png"
                alt="Shop on Etsy - ArcaneAesthetica"
                className="etsy-widget-img"
              />
            </a>
          </div>
        );


        case 'bmc':
            return (
              <a
                href="https://buymeacoffee.com/arcandor"
                target="_blank"
                rel="noopener noreferrer"
                className="widget-link bmc-widget"
              >
                <img
                  src="/bmc-logo.svg"
                  alt="Coffee cup icon"
                  className="bmc-widget-icon"
                />
                <span className="bmc-widget-text">Support Chaeknovels</span>
              </a>
            );
          

      default:
        return null;
    }
  };

  return (
    <div className="widgets-container">
      {widgetsToLoad.map((widget, index) => (
        <React.Fragment key={index}>{renderWidget(widget)}</React.Fragment>
      ))}
    </div>
  );
};

export default Widgets;