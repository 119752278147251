import React, { useRef, useEffect } from 'react';
import { FiUser, FiUpload, FiTrash2 } from "react-icons/fi"; // Combined react-icons imports
import { supabase } from '../services'; // Updated import using index.js in services folder
import { useProfile } from '../hooks'; // Updated import using index.js in hooks folder


const ProfilePictureUploader = ({ userId, currentPic, onUpload }) => {
  const fileInputRef = useRef(null);
  const { fetchProfile } = useProfile(userId);

  useEffect(() => {
    console.log('ProfilePictureUploader - Current Picture URL:', currentPic);
  }, [currentPic]);

  const handleFileChange = async (event) => {
    const avatarFile = event.target.files[0];
    if (avatarFile) {
      try {
        // Generate a unique identifier
        const uniqueId = Date.now().toString();
        const filePath = `public/${userId}/avatar_${uniqueId}.jpg`;

        const { data, error } = await supabase.storage
          .from('avatars')
          .upload(filePath, avatarFile, {
            cacheControl: '3600',
            upsert: false, // We're using a unique file name, so no need to upsert
          });

        if (error) throw error;

        if (!data || !data.path) {
          console.error('Failed to retrieve the path of the uploaded file.');
          return;
        }

        console.log('Profile picture uploaded successfully.');
        
        // Wait a moment for Supabase to process the upload and trigger the function
        setTimeout(async () => {
          // Fetch the updated profile to get the new avatar_url
          const updatedProfile = await fetchProfile(userId, true);
          
          if (onUpload && updatedProfile) {
            onUpload(updatedProfile.avatar_url);
          }
        }, 1000); // Wait for 1 second

        // Clean up old avatar files
        const { data: oldFiles, error: listError } = await supabase.storage
          .from('avatars')
          .list(`public/${userId}`);

        if (listError) throw listError;

        const filesToDelete = oldFiles
          .filter(file => file.name !== `avatar_${uniqueId}.jpg`)
          .map(file => `public/${userId}/${file.name}`);

        if (filesToDelete.length > 0) {
          const { error: deleteError } = await supabase.storage
            .from('avatars')
            .remove(filesToDelete);

          if (deleteError) console.error('Error deleting old avatar files:', deleteError.message);
        }

      } catch (err) {
        console.error('Error uploading profile picture:', err.message);
      }
    }
  };

  const handleDeleteAvatar = async () => {
    try {
      if (currentPic) {
        const urlParts = currentPic.split('/');
        const fileName = urlParts[urlParts.length - 1];
        const filePath = `public/${userId}/${fileName}`;

        const { error: deleteError } = await supabase.storage
          .from('avatars')
          .remove([filePath]);

        if (deleteError) {
          console.error('Error deleting avatar:', deleteError.message);
          return;
        }

        console.log('Avatar deleted successfully.');
        
        // Wait a moment for Supabase to process the deletion and trigger the function
        setTimeout(async () => {
          // Fetch the updated profile to get the new (null) avatar_url
          const updatedProfile = await fetchProfile(userId, true);
          
          if (onUpload) {
            onUpload(updatedProfile ? updatedProfile.avatar_url : null);
          }
        }, 1000); // Wait for 1 second
      }
    } catch (err) {
      console.error('Error deleting profile picture:', err.message);
    }
  };

  return (
    <div className="account-info">
      {currentPic ? (
        <img
          src={currentPic}
          alt="Profile"
          className="profile-pic"
        />
      ) : (
        <div className="default-profile-pic">
          <FiUser />
        </div>
      )}
      <div className="overlay">
        <button className="overlay-button" onClick={() => fileInputRef.current.click()}>
          <FiUpload /> Change
        </button>
        {currentPic && (
          <button className="overlay-button" onClick={handleDeleteAvatar}>
            <FiTrash2 /> Delete
          </button>
        )}
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept="image/*"
      />
    </div>
  );
};

export default ProfilePictureUploader;