import { FaHome, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

/**
 * Function to build a chapter index for navigating through chapters
 * @param {Array} chapters - Array of chapter objects with properties like id, number, title
 * @returns {Array} - Indexed chapters with navigation details
 */
export const buildChapterIndex = (chapters) => {
  const sortedChapters = [...chapters].sort((a, b) => a.number - b.number);

  return sortedChapters.map((chapter, index) => ({
    ...chapter,
    index,
    isFirst: index === 0,
    isSecond: index === 1, // Explicitly define second chapter
    isLast: index === sortedChapters.length - 1,
    isPrologue: chapter.number === 0,
  }));
};

/**
 * Function to get navigation buttons based on current chapter and index
 * @param {Object} currentChapter - The currently selected chapter
 * @param {Array} chapterIndex - Indexed chapters with navigation details
 * @returns {Array} - Navigation button configurations
 */




export const getNavigationButtons = (currentChapter, chapterIndex, isTopNavigation = false, slug) => {
  const buttons = [];
  const currentIndex = chapterIndex.findIndex((ch) => ch.id === currentChapter.id);

  if (currentIndex === -1) return buttons;

  const isFirstChapter = currentIndex === 0;
  const isLastChapter = currentIndex === chapterIndex.length - 1;

  if (isFirstChapter) {
    // First chapter logic
    buttons.push({ 
      type: 'home', 
      label: 'Home', 
      icon: <FaHome className="nav-icon" />,
      path: `/novel/${slug}`
    });
    if (chapterIndex.length > 1) {
      buttons.push({ 
        type: 'next', 
        label: 'Next', 
        icon: <FaChevronRight className="nav-icon" />,
        path: `/novel/${slug}/chapter/${currentChapter.number + 1}`
      });
    }
  } else if (isLastChapter) {
    // Last chapter logic
    buttons.push(
      { 
        type: 'prev', 
        label: 'Prev', 
        icon: <FaChevronLeft className="nav-icon" />,
        path: `/novel/${slug}/chapter/${currentChapter.number - 1}`
      },
      { 
        type: 'home', 
        label: 'Home', 
        icon: <FaHome className="nav-icon" />,
        path: `/novel/${slug}`
      }
    );
  } else {
    // Middle chapter logic
    if (isTopNavigation) {
      // For top navigation, only show Prev and Next
      buttons.push(
        { 
          type: 'prev', 
          label: 'Prev', 
          icon: <FaChevronLeft className="nav-icon" />,
          path: `/novel/${slug}/chapter/${currentChapter.number - 1}`
        },
        { 
          type: 'next', 
          label: 'Next', 
          icon: <FaChevronRight className="nav-icon" />,
          path: `/novel/${slug}/chapter/${currentChapter.number + 1}`
        }
      );
    } else {
      // For bottom navigation, show Prev, Home, and Next
      buttons.push(
        { 
          type: 'prev', 
          label: 'Prev', 
          icon: <FaChevronLeft className="nav-icon" />,
          path: `/novel/${slug}/chapter/${currentChapter.number - 1}`
        },
        { 
          type: 'home', 
          label: 'Home', 
          icon: <FaHome className="nav-icon" />,
          path: `/novel/${slug}`
        },
        { 
          type: 'next', 
          label: 'Next', 
          icon: <FaChevronRight className="nav-icon" />,
          path: `/novel/${slug}/chapter/${currentChapter.number + 1}`
        }
      );
    }
  }

  return buttons;
};




// ADMIN PAGE: Extracts chapter number and title from a filename.
export function extractChapterInfo(filename, chapters = []) {
  if (typeof filename !== 'string' || !filename) {  // Improved validation check
    console.error("Invalid filename:", filename);
    return { chapterNumber: null, chapterTitle: '' };
  }

  // Remove the file extension
  const baseName = filename.replace(/\.[^/.]+$/, '');  // Removes extension like .html

  // Handling special cases
  if (/^prologue$/i.test(baseName)) {
    return { chapterNumber: 0, chapterTitle: 'Prologue' };
  }

  if (/^epilogue$/i.test(baseName)) {
    // Find the next chapter number in sequence from the chapters list
    const nextChapterNumber = findNextChapterNumber(chapters);
    return { chapterNumber: nextChapterNumber, chapterTitle: 'Epilogue' };
  }

  // Extract chapter number and title using regex
  const chapterMatch = baseName.match(/chapter\s*(\d+)(?:_(.*))?/i);
  if (chapterMatch) {
    const chapterNumber = parseInt(chapterMatch[1], 10);  // Extract chapter number
    const chapterTitle = chapterMatch[2] ? chapterMatch[2].trim() : '';  // Extract title after underscore, if present
    return { chapterNumber, chapterTitle };
  }

  // Default case when no pattern is matched
  return { chapterNumber: null, chapterTitle: '' };
}


// Helper function to find the next chapter number in sequence
export function findNextChapterNumber(chapters) {
  const maxChapterNumber = Math.max(0, ...chapters.map(ch => ch.number));  // Get the highest chapter number
  return maxChapterNumber + 1;  // Next number in sequence
}



// ADMIN PAGE: Sorts an array of file objects based on their chapter numbers.
export const sortChapterFiles = (files) => {
  return [...files].sort((a, b) => {
    const aInfo = extractChapterInfo(a.name);
    const bInfo = extractChapterInfo(b.name);
    return (aInfo.chapterNumber || 0) - (bInfo.chapterNumber || 0);
  });
};