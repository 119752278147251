// NovelPageMobileSkeleton.js
import React from 'react';
import './NovelPageMobileSkeleton.css'; // Create a new CSS file for the skeleton styles

const NovelPageMobileSkeleton = () => {
  return (
    <div className="npm-skeleton-page">
      {/* Header Skeleton */}
      <div className="npm-skeleton-header">
        <div className="npm-skeleton-image"></div>
        <div className="npm-skeleton-info">
          <div className="npm-skeleton-title"></div>
          <div className="npm-skeleton-subtitle"></div>
          <div className="npm-skeleton-stats">
            <div className="npm-skeleton-stat-item"></div>
            <div className="npm-skeleton-stat-item"></div>
            <div className="npm-skeleton-stat-item"></div>
          </div>
        </div>
      </div>

      {/* Summary Skeleton */}
      <div className="npm-skeleton-summary">
        <div className="npm-skeleton-line"></div>
        <div className="npm-skeleton-line"></div>
        <div className="npm-skeleton-line short"></div>
      </div>

      {/* Author and Publisher Skeleton */}
      <div className="npm-skeleton-author-publisher">
        <div className="npm-skeleton-line"></div>
        <div className="npm-skeleton-line"></div>
      </div>

      {/* Tags Skeleton */}
      <div className="npm-skeleton-tags">
        <div className="npm-skeleton-tag-item"></div>
        <div className="npm-skeleton-tag-item"></div>
        <div className="npm-skeleton-tag-item"></div>
      </div>

      {/* Chapters Skeleton */}
      <div className="npm-skeleton-chapters">
        <div className="npm-skeleton-chapter-item"></div>
        <div className="npm-skeleton-chapter-item"></div>
        <div className="npm-skeleton-chapter-item"></div>
        <div className="npm-skeleton-chapter-item"></div>
      </div>

      {/* Rating Section Skeleton */}
      <div className="npm-skeleton-rating"></div>

      {/* Comments Section Skeleton */}
      <div className="npm-skeleton-comments">
        <div className="npm-skeleton-comment-item"></div>
        <div className="npm-skeleton-comment-item"></div>
        <div className="npm-skeleton-comment-item"></div>
      </div>
    </div>
  );
};

export default NovelPageMobileSkeleton;
