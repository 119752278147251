// src/context/ModalContext.js
import React, { createContext, useContext, useState, useCallback } from 'react';

const ModalContext = createContext();

export function ModalProvider({ children }) {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [loginModalMessage, setLoginModalMessage] = useState('');

  const openLoginModal = useCallback((message = '') => {
    setLoginModalMessage(message);
    setIsLoginModalOpen(true);
  }, []);

  const closeLoginModal = useCallback(() => {
    setIsLoginModalOpen(false);
    setLoginModalMessage('');
  }, []);

  const openSignUpModal = useCallback(() => {
    setIsSignUpModalOpen(true);
  }, []);

  const closeSignUpModal = useCallback(() => {
    setIsSignUpModalOpen(false);
  }, []);

  return (
    <ModalContext.Provider
      value={{
        openLoginModal,
        closeLoginModal,
        openSignUpModal,
        closeSignUpModal,
        isLoginModalOpen,
        isSignUpModalOpen,
        loginModalMessage,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export const useModal = () => useContext(ModalContext);
