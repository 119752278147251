import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { FiUser, FiMenu, FiX } from "react-icons/fi";
import { SearchResults } from '../pages';
import { useModal } from '../context';
import { useAuth } from '../context';
import { debounce } from 'lodash';

export function Navbar() {
  const { user, profile, loading, logout, isAdmin } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [query, setQuery] = useState('');
  const dropdownRef = useRef(null);
  const searchRef = useRef(null);
  const searchInputRef = useRef(null);
  const menuRef = useRef(null);
  const { openLoginModal } = useModal();

  const handleLoginClick = useCallback((e) => {
    e.preventDefault();
    openLoginModal();
    setMenuOpen(false);
  }, [openLoginModal]);

  const handleCloseResults = useCallback(() => {
    setQuery('');
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && !event.target.closest('.menu-toggle')) {
        setMenuOpen(false);
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        handleCloseResults();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleCloseResults]);

  const handleAvatarClick = useCallback((e) => {
    e.stopPropagation();
    setDropdownOpen((prevOpen) => !prevOpen);
  }, []);

  const debouncedSetQuery = useMemo(() => debounce((value) => setQuery(value), 300), []);

  const handleSearchChange = useCallback((e) => {
    debouncedSetQuery(e.target.value);
  }, [debouncedSetQuery]);

  const toggleMenu = useCallback(() => {
    setMenuOpen((prevOpen) => !prevOpen);
  }, []);

  const navLinks = useMemo(() => [
    { to: "/", label: "home" },
    { to: "/about", label: "about" },
    { href: "https://buymeacoffee.com/arcandor", label: "support", external: true },
    ...(isAdmin ? [{ to: "/admin", label: "admin" }] : []),
  ], [isAdmin]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <nav className="navbar" role="navigation" aria-label="Main Navigation">
      <div className="navbar-container">
        <div className="navbar-logo">
          <Link to="/" className="logo-link" aria-label="Home">
            chaeknovels
            <span>TRANSLATED</span>
          </Link>
        </div>
        <div className={`navbar-menu ${menuOpen ? 'open' : ''}`} ref={menuRef}>
          <button className="menu-close" onClick={toggleMenu} aria-label="Close menu">
            <FiX />
          </button>
          <ul className="navbar-links">
            {navLinks.map(({ to, href, label, external }) => (
              <li key={label}>
                {external ? (
                  <a href={href} target="_blank" rel="noopener noreferrer" aria-label={label} onClick={() => setMenuOpen(false)}>
                    {label}
                  </a>
                ) : (
                  <Link to={to} aria-label={label} onClick={() => setMenuOpen(false)}>{label}</Link>
                )}
              </li>
            ))}
            {user ? (
              <li className="navbar-user" ref={dropdownRef}>
                <div 
                  className="profile-wrapper"
                  onClick={handleAvatarClick}
                  onKeyPress={(e) => e.key === 'Enter' && handleAvatarClick(e)}
                  tabIndex={0}
                  role="button"
                  aria-haspopup="true"
                  aria-expanded={dropdownOpen}
                >
                  {profile && profile.avatar_url ? (
                    <img src={profile.avatar_url} alt={profile.username} className="navbar-profile-pic" />
                  ) : (
                    <FiUser className="default-profile-pic" aria-label="Default avatar" />
                  )}
                  <span className="username">{profile?.username}</span>
                </div>
                {menuOpen ? (
                  <ul className="user-menu-mobile">
                    <li><Link to="/account" onClick={() => setMenuOpen(false)}>Account</Link></li>
                    <li><Link to="/bookmarks" onClick={() => setMenuOpen(false)}>Bookmarks</Link></li>
                    <li><button onClick={() => { logout(); setMenuOpen(false); }}>Logout</button></li>
                  </ul>
                ) : (
                  <div className={`dropdown-menu ${dropdownOpen ? 'open' : ''}`}>
                    <Link to="/account" onClick={() => setDropdownOpen(false)}>Account</Link>
                    <Link to="/bookmarks" onClick={() => setDropdownOpen(false)}>Bookmarks</Link>
                    <button onClick={() => { logout(); setDropdownOpen(false); }}>Logout</button>
                  </div>
                )}
              </li>
            ) : (
              <li>
                <button className="navbar-link-button" onClick={handleLoginClick} aria-label="Login">
                  login
                </button>
              </li>
            )}
          </ul>
          <div className="navbar-search" ref={searchRef}>
            <input
              ref={searchInputRef}
              type="text"
              placeholder="search novels..."
              onChange={handleSearchChange}
              onFocus={() => setQuery(searchInputRef.current.value)}
              className="search-input"
              aria-label="Search novels"
            />
            {query && <SearchResults query={query} onClose={handleCloseResults} />}
          </div>
        </div>
        <button className="menu-toggle" onClick={toggleMenu} aria-label="Toggle menu">
          {user ? (
            profile && profile.avatar_url ? (
              <img src={profile.avatar_url} alt={profile.username} className="navbar-profile-pic" />
            ) : (
              <FiUser className="default-profile-pic" aria-label="Default avatar" />
            )
          ) : (
            <FiMenu />
          )}
        </button>

      </div>
    </nav>
  );



}

export default React.memo(Navbar);