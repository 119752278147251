// RealTimeUpdates.js

export const handleNovelsChange = (payload, setNovels) => {
  switch (payload.eventType) {
    case 'INSERT':
      setNovels((prevData) => [...prevData, payload.new]);
      break;
    case 'UPDATE':
      setNovels((prevData) =>
        prevData.map((novel) =>
          novel.id === payload.new.id ? payload.new : novel
        )
      );
      break;
    case 'DELETE':
      setNovels((prevData) =>
        prevData.filter((novel) => novel.id !== payload.old.id)
      );
      break;
    default:
      break;
  }
};

export const handleChaptersChange = (payload, setChapters) => {
  switch (payload.eventType) {
    case 'INSERT':
      setChapters((prevData) => [...prevData, payload.new]);
      break;
    case 'UPDATE':
      setChapters((prevData) =>
        prevData.map((chapter) =>
          chapter.id === payload.new.id ? payload.new : chapter
        )
      );
      break;
    case 'DELETE':
      setChapters((prevData) =>
        prevData.filter((chapter) => chapter.id !== payload.old.id)
      );
      break;
    default:
      break;
  }
};

// Add a new handler for the About Page
export const handleAboutPageChange = (payload, setAboutContent) => {
  switch (payload.eventType) {
    case 'INSERT':
    case 'UPDATE':
      // Directly update the aboutContent state
      setAboutContent({ title: payload.new.title, content: payload.new.content });
      break;
    default:
      break;
  }
};
