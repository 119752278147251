// src/components/ModalManager.js
import React from 'react';
import { LoginPage, SignUpPage } from '../pages';
import { useModal } from '../context';

const ModalManager = () => {
  const {
    isLoginModalOpen,
    closeLoginModal,
    openSignUpModal,
    isSignUpModalOpen,
    closeSignUpModal,
    openLoginModal,
    loginModalMessage,
  } = useModal();

  const handleModalBackgroundClick = (event, closeModal) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  return (
    <>
      {/* Render Login Modal */}
      {isLoginModalOpen && (
        <div 
          className="modal modal-overlay"
          onClick={(e) => handleModalBackgroundClick(e, closeLoginModal)}
          aria-modal="true"
          role="dialog"
        >
          <div 
            className="modal-content" 
            onClick={(e) => e.stopPropagation()}
          >
            <LoginPage 
              onClose={closeLoginModal} 
              openSignUpModal={openSignUpModal} 
              contextMessage={loginModalMessage} 
            />
          </div>
        </div>
      )}

      {/* Render Sign Up Modal */}
      {isSignUpModalOpen && (
        <div 
          className="modal modal-overlay"
          onClick={(e) => handleModalBackgroundClick(e, closeSignUpModal)}
          aria-modal="true"
          role="dialog"
        >
          <div 
            className="modal-content" 
            onClick={(e) => e.stopPropagation()}
          >
            <SignUpPage 
              onClose={closeSignUpModal} 
              openLoginModal={openLoginModal}
              contextMessage={loginModalMessage} 
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ModalManager;