import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../services/supabaseClient';
import { uploadFile } from '../services/adminService';
import './AnnouncementUpdater.css';
import '../pages/announcement-styles.css';

const AnnouncementUpdater = () => {
  const [title, setTitle] = useState('');
  const [file, setFile] = useState(null);
  const [currentAnnouncement, setCurrentAnnouncement] = useState(null);
  const [htmlContent, setHtmlContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFileAdded, setIsFileAdded] = useState(false);

  const [error, setError] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [previewContent, setPreviewContent] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchCurrentAnnouncement();
    loadAnnouncementStyles();
  }, []);

  const fetchCurrentAnnouncement = async () => {
    try {
      const { data, error } = await supabase
        .from('announcement')
        .select('*')
        .eq('id', 1)
        .single();

      if (error) throw error;
      setCurrentAnnouncement(data);
      setTitle(data.title);

      if (data.message) {
        const response = await fetch(data.message);
        const html = await response.text();
        setHtmlContent(html);
      }
    } catch (error) {
      console.error('Error fetching current announcement:', error);
      setError('Failed to fetch current announcement');
    }
  };

  const loadAnnouncementStyles = async () => {
    try {
      const response = await fetch('/announcement-styles.css');
      const styles = await response.text();
      const styleSheet = document.createElement('style');
      styleSheet.innerText = styles;
      document.head.appendChild(styleSheet);
    } catch (error) {
      console.error('Error loading announcement styles:', error);
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setIsFileAdded(!!selectedFile); // Track if the file is added
    setShowPreview(false);
    setUploadStatus('');
    setError(null);
  };

  const handleClearFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setFile(null);
    setShowPreview(false);
    setPreviewContent('');
    setUploadStatus('');
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setUploadStatus('Uploading...');

    try {
      if (!file) throw new Error('Please select a file to upload');

      // Step 1: Upload file to storage bucket
      setUploadStatus('Uploading file...');
      const { publicUrl, error: uploadError } = await uploadFile('misc-opentext', file, true);
      if (uploadError) throw uploadError;

      // Step 2: Update the announcement in the database with new title and URL
      setUploadStatus('Updating announcement...');
      const { error: updateError } = await supabase
        .from('announcement')
        .update({ title, message: publicUrl })
        .eq('id', 1)
        .select();

      if (updateError) throw updateError;

      // Step 3: Fetch the updated announcement
      await fetchCurrentAnnouncement();
      
      setFile(null);
      setShowPreview(false);
      setUploadStatus('Announcement updated successfully!');

      setTimeout(() => {
        setUploadStatus('');
      }, 5000); // Clear the status after 5 seconds
    } catch (error) {
      console.error('Error updating announcement:', error);
      setError(error.message);
      setUploadStatus('Update failed');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreview = async () => {
    if (!file) {
      setError('Please select a file to preview');
      return;
    }
    
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewContent(e.target.result);
        setShowPreview(true);
      };
      reader.readAsText(file);
    } catch (error) {
      console.error('Error reading file:', error);
      setError('Failed to read the file for preview');
    }
  };

  return (
    <div className="announcement-updater">
      <h2>Update Announcement</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
            <label htmlFor="title">Announcement Title:</label>
            <div className="input-wrapper">
                <input
                    type="text"
                    id="title"
                    value={title}
                    onChange={handleTitleChange}
                    required
                />
            </div>
    </div>
        

        <div className="form-group">
            <label htmlFor="file">Upload HTML File:</label>
            <div className="input-wrapper file-input-group">
                <input
                    type="file"
                    id="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept=".html"
                    required
                />
                <button type="button" onClick={handleClearFile} className="btn btn-secondary">
                    Clear
                </button>
            </div>
        </div>
  
        <div className="button-group">
          <button type="button" onClick={handlePreview} disabled={isLoading || !file} className={`btn btn-preview ${isFileAdded ? 'file-added' : ''} ${isLoading || !file ? 'disabled' : ''}`}>
            Preview
          </button>
          <button type="submit" disabled={isLoading} className="btn btn-primary">
            {isLoading ? 'Updating...' : 'Update Announcement'}
          </button>
        </div>
      </form>
  
      {error && <p className="error-message">{error}</p>}
      {uploadStatus && <p className="status-message">{uploadStatus}</p>}
  
      {showPreview && (
        <div className="preview-section">
          <h3>Preview</h3>
          <div className="announcement-container">
            <h4 className="announcement-title">{title}</h4>
            <div
              className="announcement-content"
              dangerouslySetInnerHTML={{ __html: previewContent }}
            />
          </div>
        </div>
      )}
  
      {currentAnnouncement && (
        <div className="current-announcement">
          <h4>Current Announcement</h4>
          <p>
            <strong>Title:</strong> {currentAnnouncement.title}
          </p>
          <p>
            <strong>Message URL:</strong> {currentAnnouncement.message}
          </p>
  
          <div className="announcement-preview">
            <h5>Homepage Preview:</h5>
            <div className="announcement-container">
              <div className="announcement">
                <h3 className="announcement-title">{currentAnnouncement.title}</h3>
                <div
                  className="announcement-content"
                  dangerouslySetInnerHTML={{ __html: htmlContent }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );


};

export default AnnouncementUpdater;