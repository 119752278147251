import React, { createContext, useState, useEffect, useCallback, useMemo, useRef, useContext } from 'react';
import { supabase, subscribeToNovels, subscribeToChapters } from '../services';
import { handleNovelsChange, handleChaptersChange } from '../utils';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [latestUpdates, setLatestUpdates] = useState([]);
  const [novels, setNovels] = useState([]);
  const [novelDetails, setNovelDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const novelsSubscriptionRef = useRef(null);
  const chaptersSubscriptionRef = useRef(null);

  const fetchNovels = useCallback(async () => {
    try {
      setLoading(true);
      const { data: novelsData, error: novelsError } = await supabase
        .from('novels')
        .select('id, title, imageURL, totalViews, totalChapters, summary, followers, "AverageRating", tags, slug')
        .order('id', { ascending: false });
      if (novelsError) throw novelsError;
      setNovels(novelsData);
    } catch (error) {
      console.error('Error fetching novels:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchLatestUpdates = useCallback(async () => {
    try {
      const { data: chaptersData, error: updatesError } = await supabase
        .from('chapters')
        .select(`
          id,
          novelId,  
          number,
          title,
          uploadedDate,
          novels (id, title, imageURL, slug)
        `)
        .order('uploadedDate', { ascending: false })
        .limit(15);
      if (updatesError) throw updatesError;

      const groupedUpdates = chaptersData.reduce((acc, update) => {
        if (!acc[update.novelId]) {
          acc[update.novelId] = {
            ...update.novels,
            chapters: [],
          };
        }
        if (acc[update.novelId].chapters.length < 3) {
          acc[update.novelId].chapters.push(update);
        }
        return acc;
      }, {});
      setLatestUpdates(Object.values(groupedUpdates));
    } catch (error) {
      console.error('Error fetching latest updates:', error);
    }
  }, []);

  const fetchNovelDetails = useCallback(async (slug) => {
    if (!slug) return;
  
    setLoading(true);
    try {
      const { data: novelData, error: novelError } = await supabase
        .from('novels')
        .select('*')
        .eq('slug', slug)
        .single();
  
      if (novelError) throw novelError;
      if (!novelData) {
        console.error(`No novel found with slug: ${slug}`);
        setError('Novel not found.');
        return;
      }
  
      const { data: chaptersData, error: chaptersError } = await supabase
        .from('chapters')
        .select('*')
        .eq('novelId', novelData.id)
        .order('number', { ascending: true });
  
      if (chaptersError) throw chaptersError;
  
      setNovelDetails((prev) => ({
        ...prev,
        [slug]: {
          ...novelData,
          chapters: chaptersData,
        },
      }));

      return {
        ...novelData,
        chapters: chaptersData,
      };
    } catch (error) {
      console.error('Error fetching novel details:', error.message);
      setError('An error occurred while loading the novel.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchNovels();
    fetchLatestUpdates();
  }, [fetchNovels, fetchLatestUpdates]);

  useEffect(() => {
    if (!novelsSubscriptionRef.current) {
      novelsSubscriptionRef.current = subscribeToNovels((payload) => {
        handleNovelsChange(payload, setNovels, setNovelDetails);
      });
    }
    return () => {
      if (novelsSubscriptionRef.current) {
        novelsSubscriptionRef.current.unsubscribe();
        novelsSubscriptionRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!chaptersSubscriptionRef.current) {
      chaptersSubscriptionRef.current = subscribeToChapters((payload) => {
        console.log('Chapter update received:', payload);
        const updatedChapter = payload.new;

        handleChaptersChange(payload, (updatedChapters) => {
          setLatestUpdates((prevUpdates) => {
            const novelIndex = prevUpdates.findIndex((novel) => novel.id === updatedChapter.novelId);
            if (novelIndex === -1) return prevUpdates;

            const updatedNovel = { ...prevUpdates[novelIndex] };
            const chapterIndex = updatedNovel.chapters.findIndex((chapter) => chapter.id === updatedChapter.id);
            if (chapterIndex !== -1) {
              updatedNovel.chapters[chapterIndex] = updatedChapter;
            } else if (updatedNovel.chapters.length < 3) {
              updatedNovel.chapters.push(updatedChapter);
            }

            return [
              ...prevUpdates.slice(0, novelIndex),
              updatedNovel,
              ...prevUpdates.slice(novelIndex + 1),
            ];
          });

          setNovelDetails((prev) => {
            const novelId = updatedChapter.novelId;
            if (!novelId || !prev[novelId]) return prev;

            const updatedNovelChapters = prev[novelId].chapters.map((ch) =>
              ch.id === updatedChapter.id ? updatedChapter : ch
            );

            console.log('Updating chapters in novelDetails:', updatedNovelChapters);

            return {
              ...prev,
              [novelId]: {
                ...prev[novelId],
                chapters: updatedNovelChapters,
              },
            };
          });
        });
      });
    }
    return () => {
      if (chaptersSubscriptionRef.current) {
        chaptersSubscriptionRef.current.unsubscribe();
        chaptersSubscriptionRef.current = null;
      }
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      novels,
      setNovels,
      novelDetails,
      setNovelDetails,
      fetchNovels,
      fetchNovelDetails,
      loading,
      latestUpdates,
      fetchLatestUpdates,
      setLoading,
      error, 
      setError,
    }),
    [
      novels,
      novelDetails,
      loading,
      latestUpdates,
      fetchNovels,
      fetchNovelDetails,
      fetchLatestUpdates,
      error, 
    ]
  );

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useApp must be used within an AppProvider");
  }
  return context;
};

export default AppProvider;