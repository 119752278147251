import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../services';
import { useAuth } from '../context/AuthContext';
import { useSso } from '../context/SsoContext';
import { useModal } from '../context/ModalContext';
import '../pages/SignUpPage.css';
import { FaDiscord, FaEye, FaEyeSlash } from "react-icons/fa";

export function LoginPage({ onClose }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { login } = useAuth();
  const { generateSsoToken } = useSso();
  const { closeLoginModal, openSignUpModal, loginModalMessage } = useModal();

  // Handle authentication
  const handleLogin = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const user = await login(email, password, rememberMe);
      if (user) {
        await generateSsoToken();
        closeLoginModal();
      } else {
        throw new Error('Login failed. Please check your credentials and try again.');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError(err.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [email, password, rememberMe, login, generateSsoToken, closeLoginModal]);

  // Handle Discord OAuth login
  const handleDiscordLogin = async () => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'discord',
        options: {
          redirectTo: window.location.origin,
        },
      });
      
      if (error) {
        console.error('Error with Discord login:', error.message);
        setError('Failed to login with Discord. Please try again.');
      }
    } catch (err) {
      console.error('Error during Discord login:', err.message);
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Handle switch to signup modal
  const handleSignUpClick = (e) => {
    e.preventDefault();
    if (!loading) {
      closeLoginModal();
      openSignUpModal();
    }
  };

  // Prevent modal close during loading
  useEffect(() => {
    const handleBeforeClose = () => {
      return !loading;
    };

    if (onClose) {
      onClose.current = handleBeforeClose;
    }
  }, [loading, onClose]);

  return (
    <>
      <h2 id="login-title">Login to your account</h2>
      {loginModalMessage && <p className="context-message">{loginModalMessage}</p>}
      
      <form className="login-form" onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="modal-input"
          disabled={loading}
          aria-label="Email"
        />
        <div className="password-input-container">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="modal-input password-input"
            disabled={loading}
            aria-label="Password"
          />
          <button 
            type="button" 
            onClick={togglePasswordVisibility} 
            className="password-toggle-btn"
            aria-label={showPassword ? "Hide password" : "Show password"}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
        <div className="login-options">
          <label className="remember-me-label">
            <input
              type="checkbox"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              disabled={loading}
              aria-label="Remember me"
            />
            <span>Remember me</span>
          </label>
          <Link 
            to="/forgot-password" 
            onClick={(e) => e.preventDefault()} 
            className="forgot-password-link"
          >
            Forgot password?
          </Link>
        </div>
        {error && <p className="error-message">{error}</p>}
        <button 
          type="submit" 
          disabled={loading} 
          className="modal-button login-button" 
          aria-busy={loading}
        >
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>
      
      <div className="login-divider">
        <span>OR</span>
      </div>
      
      <button 
        onClick={handleDiscordLogin} 
        className="modal-button discord-login-button"
        disabled={loading}
      >
        <FaDiscord className="discord-icon" />
        Login with Discord
      </button>
      
      <p className="register-prompt">
        Don't have an account? {' '}
        <button 
          className="switch-modal-button" 
          onClick={handleSignUpClick}
          disabled={loading}
        >
          Create a new account
        </button>
      </p>
    </>
  );
}

export default LoginPage;