import React from 'react';
import { useAuth } from '../context/AuthContext';
import { useModal } from '../context/ModalContext';
import './Comments.css';

const CommentHeader = ({ commentCount }) => {
  const { user, profile, logout } = useAuth();
  const { openLoginModal } = useModal();

  
  const formatCommentCount = (count) => {
    if (count === 0) return 'NO COMMENTS';
    if (count === 1) return '1 COMMENT';
    if (count >= 1000) return `${(count / 1000).toFixed(1)}K COMMENTS`;
    return `${count.toLocaleString()} COMMENTS`;
  };
  

  const handleAuthAction = () => {
    if (user) {
      logout();
    } else {
      openLoginModal('Join the discussion');
    }
  };

  return (
    <div className="comments-header">
      <div className="comments-header-left">
        <div className="comments-count">
          {formatCommentCount(commentCount)}
        </div>
        {user && profile && (
          <div className="user-info">
            <span className="username">
              {profile.username || user.displayName}
            </span>
          </div>
        )}
      </div>
      <button 
        className="comments-auth-button" 
        onClick={handleAuthAction}
        aria-label={user ? 'Logout from the discussion' : 'Login to join the discussion'}

      >
        {user ? 'LOGOUT' : 'LOGIN'}
      </button>
    </div>
  );
};

export default React.memo(CommentHeader);