import React, { createContext, useState, useEffect, useCallback } from 'react';
import { supabase } from '../services'; // Updated import using index.js in services folder
import { AboutPageRealTimeSubscriptions, handleAboutPageChange } from '../services'; // Ensure this path is correct

export const AboutPageContext = createContext();

export const AboutPageProvider = ({ children }) => {
  const [aboutContent, setAboutContent] = useState({ title: '', content: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAboutPageContent = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      console.log('Fetching about page content...');
  
      const { data, error } = await supabase
        .from('about_page')
        .select('title, content')
        .eq('id', 1)
        .single();
  
      // Check if 'data' is undefined or null
      if (error || !data) {
        console.error('Error fetching data or data is undefined:', error);
        throw new Error('Data not available from Supabase.');
      }
  
      console.log('Fetched data:', data);  // Log fetched data to confirm structure
  
      // Fetch the actual HTML content from the URL
      const response = await fetch(data.content);
      if (!response.ok) throw new Error('Failed to fetch content from URL');
      const htmlContent = await response.text();
  
      // Update the state safely
      setAboutContent({ title: data.title, content: htmlContent });
    } catch (error) {
      console.error('Error fetching about page content:', error);
      setError('Failed to load content. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);
  
  
  

  useEffect(() => {
    // Fetch the initial content
    fetchAboutPageContent();

    // Subscribe to about page updates
    const aboutpageChannel = AboutPageRealTimeSubscriptions((payload) => {
      handleAboutPageChange(payload, setAboutContent);
    });

    // Cleanup function to unsubscribe and prevent memory leaks
    return () => {
      // Ensure the channel is unsubscribed properly
      aboutpageChannel.unsubscribe(); // Adjust this based on your library
    };
  }, [fetchAboutPageContent]);

  return (
    <AboutPageContext.Provider value={{ aboutContent, loading, error, fetchAboutPageContent }}>
      {children}
    </AboutPageContext.Provider>
  );
};

export default AboutPageProvider;