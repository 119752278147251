import React, { useContext } from 'react';
import { AboutPageContext } from '../context/AboutPageContext'; // Adjust the import path if needed
import { cleanHTMLContent } from '../utils/cleanHTMLContent'; // Ensure this function properly sanitizes HTML content
import './AboutPage.css'; // Ensure this CSS file contains the styles for your HTML content
import { useSEO } from '../hooks';


const AboutPage = () => {
  const { aboutContent, loading, error } = useContext(AboutPageContext);

  const seoData = {
    title: aboutContent?.title
      ? `${aboutContent.title} | Chaeknovels TRANSLATED`
      : 'About Us | Chaeknovels',
    description:
      'Learn more about Chaeknovels TRANSLATED, your source for Korean novels in English translation. Discover our mission and commitment to bringing Korean romance novels to English-speaking readers.',
    type: 'website',
    keywords:
      'about chaeknovels, korean novel translations, korean literature, web novels, light novels, manhwa, romance, smut',
    structuredData: {
      "@context": "https://schema.org",
      "@type": "AboutPage",
      "name": aboutContent?.title || "About Chaeknovels TRANSLATED",
      "description":
        "About Chaeknovels TRANSLATED - Korean Romance Novels in English",
      "url": "https://chaeknovels.com/about",
      "publisher": {
        "@type": "Organization",
        "name": "Chaeknovels TRANSLATED",
        "url": "https://chaeknovels.com"
      }
    }
  };

  const { HelmetTags } = useSEO(seoData);

  if (loading) {
    return <div className="about-page loading">Loading...</div>;
  }

  if (error) {
    return <div className="about-page error">{error}</div>;
  }

  if (!aboutContent || !aboutContent.title || !aboutContent.content) {
    return <div className="about-page error">Content not available</div>;
  }

  return (
    <div className="about-page">
       <HelmetTags />
      <h1 className="about-title">{aboutContent.title}</h1>
      <div
        className="about-content"
        dangerouslySetInnerHTML={{ __html: cleanHTMLContent(aboutContent.content) }}
      />
    </div>
  );
};


export default AboutPage;