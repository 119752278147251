import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../services';
import { useAuth } from '../context';
import { IoEye } from "react-icons/io5";
import { FaListAlt } from "react-icons/fa";
import { renderStars, timeAgo } from '../utils';
import './HomePage.css';

export function Bookmarks() {
  const { user } = useAuth();
  const [bookmarkedNovels, setBookmarkedNovels] = useState([]);
  const [latestUpdates, setLatestUpdates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getNovelNoun = useCallback((count) => (count === 1 ? 'novel' : 'novels'), []);

  const fetchBookmarkedNovels = useCallback(async () => {
    if (!user) {
      setError('You need to be logged in to view bookmarks.');
      setLoading(false);
      return;
    }

    setLoading(true);
    console.log("Fetching bookmarks for user:", user.id);

    try {
      const { data: bookmarksData, error: bookmarksError } = await supabase
        .from('bookmarks')
        .select(`
          novel_id,
          novels (
            id,
            slug,
            title,
            imageURL,
            AverageRating,
            totalViews,
            tags,
            chapters (id)
          )
        `)
        .eq('user_id', user.id);

      if (bookmarksError) throw bookmarksError;

      const processedNovels = bookmarksData.map((bookmark) => ({
        ...bookmark.novels,
        totalChapters: bookmark.novels.chapters ? bookmark.novels.chapters.length : 0,
      }));

      setBookmarkedNovels(processedNovels);

      const { data: updatesData, error: updatesError } = await supabase
        .from('chapters')
        .select(`
          id,
          novelId,
          number,
          title,
          uploadedDate,
          novels (
            id,
            slug,
            title,
            imageURL
          )
        `)
        .order('uploadedDate', { ascending: false })
        .limit(15);

      if (updatesError) throw updatesError;

      if (processedNovels.length > 0) {
        const novelIds = processedNovels.map((novel) => novel.id);
        const groupedUpdates = updatesData.reduce((acc, update) => {
          if (novelIds.includes(update.novelId)) {
            if (!acc[update.novelId]) {
              acc[update.novelId] = {
                ...update.novels,
                chapters: [],
              };
            }
            acc[update.novelId].chapters.push(update);
          }
          return acc;
        }, {});

        setLatestUpdates(Object.values(groupedUpdates));
      }

      setLoading(false);
    } catch (err) {
      console.error('Failed to fetch data:', err);
      setError('Failed to fetch data. Please try again later.');
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (!bookmarkedNovels.length && user) {
      fetchBookmarkedNovels();
    }
  }, [fetchBookmarkedNovels, bookmarkedNovels.length, user]);

  const memoizedLatestUpdates = useMemo(() => latestUpdates, [latestUpdates]);
  const memoizedBookmarkedNovels = useMemo(() => bookmarkedNovels, [bookmarkedNovels]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="home-page">
      <h2 className="section-header">Bookmarks</h2>

      <section className="latest-updates">
        <h2 className="section-header">Recently Updated</h2>
        <div className="updates-grid">
          {memoizedLatestUpdates.length > 0 ? (
            memoizedLatestUpdates.map((novel) => (
              <div key={novel.id} className="update-card">
                <Link to={`/novel/${novel.slug}`} className="book-cover">
                  <div 
                    className="book-image" 
                    style={{
                      backgroundImage: novel.imageURL ? `url(${novel.imageURL})` : 'none', 
                      backgroundColor: 'var(--light-peach)'
                    }}>
                  </div>
                </Link>
                <div className="update-info">
                  <h3>{novel.title}</h3>
                  {novel.chapters && novel.chapters.slice(0, 4).map((chapter) => (
                    <Link
                      key={chapter.id}
                      to={`/novel/${novel.slug}/chapter/${chapter.number}`}
                      className="chapter-link"
                    >
                      <span className="chapter-number">Chapter {chapter.number}</span>
                      <span className="upload-time">{timeAgo(chapter.uploadedDate)}</span>
                    </Link>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <p>No updates available for your bookmarked novels.</p>
          )}
        </div>
      </section>

      <section className="novels">
        <h2 className="section-header">
          You are following {memoizedBookmarkedNovels.length} {getNovelNoun(memoizedBookmarkedNovels.length)}:
        </h2>
        <div className="novels-grid">
          {memoizedBookmarkedNovels.length > 0 ? (
            memoizedBookmarkedNovels.map((novel) => (
              <div key={novel.id} className="novel-card-wrapper">
                <Link 
                  to={`/novel/${novel.slug}`} 
                  className="card-link-overlay" 
                  aria-label={`Read ${novel.title}`} 
                />
                
                <div className="novel-card">
                  <div className="book-cover">
                    <div
                      className="book-image"
                      style={{
                        backgroundImage: novel.imageURL ? `url(${novel.imageURL})` : 'none',
                        backgroundColor: 'var(--light-peach)'
                      }}
                    />
                  </div>
                  <div className="novel-info">
                    <h3>{novel.title}</h3>
                    <div className="rating-container">
                      {renderStars(novel.AverageRating)}
                    </div>
                    <div className="novel-stats">
                      <div className="novel-stat">
                        <IoEye /> <span>{novel.totalViews || 0}</span>
                      </div>
                      <div className="novel-stat">
                        <FaListAlt /> <span>{novel.totalChapters || 0}</span>
                      </div>
                    </div>
                    <div className="tags">
                      {Array.isArray(novel.tags) && novel.tags.map((tag, index) => (
                        <Link 
                          key={index} 
                          to={`/genre/${tag.toLowerCase().trim()}`} 
                          className="tag-item"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {tag.trim()}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>You have not bookmarked any novels yet.</p>
          )}
        </div>
      </section>
    </div>
  );
}

export default React.memo(Bookmarks);