import React, {
  Suspense,
  lazy,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../services';
import './ChapterPage.css';
import { useAuth, useModal, AppContext } from '../context';
import { 
  cleanHTMLContent, 
  buildChapterIndex, 
  getNavigationButtons, 
  fetchUserRating,
  getFormattedChapterTitle,
  generateChapterStructuredData,
} from '../utils';
import { useSEO } from '../hooks';

import { ScrollArrows, ChapterContent, ProgressBar, SkeletonScreen, LoadingSpinner } from '../components';
import { CommentSection } from '.';

const StarRating = lazy(() => import('../components/StarRating'));

export function ChapterPage() {
  const { slug, chapterNumber } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { openLoginModal } = useModal();
  const { novelDetails, fetchNovelDetails } = useContext(AppContext);
  const chapterContentRef = useRef(null);

  // Initialize states with refs to track loaded state
  const initialLoadComplete = useRef(false);
  const currentChapterRef = useRef(chapterNumber);

  const [novel, setNovel] = useState(() => novelDetails[slug] || null);
  const [chapterData, setChapterData] = useState(null);
  const [allChapters, setAllChapters] = useState([]);
  const [chapterIndex, setChapterIndex] = useState([]);
  const [error, setError] = useState(null);
  const [userRating, setUserRating] = useState({ rating: 0, userHasRated: false });
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isNavigating, setIsNavigating] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [preloadedChapters, setPreloadedChapters] = useState({});

  // SEO data memoization
  const seoData = useMemo(() => {
    if (novel && chapterData) {
      return {
        title: `${novel.title} - Chapter ${chapterData.number} | Chaeknovels`,
        description: `Read ${novel.title} (${novel.korean}) Chapter ${chapterData.number} online. ${novel.summary?.substring(0, 150)}...`,
        type: 'article',
        imageUrl: novel.imageURL,
        keywords: `${novel.title}, ${novel.korean}, ${novel.author}, chapter ${chapterData.number}, korean novel, web novel, manhwa, chaebol, read online`,
        author: novel.author,
        structuredData: generateChapterStructuredData(novel, chapterData)
      };
    }
    return {
      title: 'Loading Chapter...',
      description: '',
      type: 'website'
    };
  }, [novel, chapterData]);

  const { HelmetTags } = useSEO(seoData);

  const fetchChapterContent = useCallback(async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Failed to fetch chapter content');

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let content = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        content += decoder.decode(value, { stream: true });
      }

      return cleanHTMLContent(content);
    } catch (error) {
      console.error('Failed to fetch chapter content:', error.message);
      throw error;
    }
  }, []);

  const preloadAdjacentChapters = useCallback(async (currentChapterNumber) => {
    const adjacentChapters = [currentChapterNumber - 1, currentChapterNumber + 1];
    
    for (const chapterNum of adjacentChapters) {
      if (chapterNum > 0 && chapterNum <= allChapters.length && !preloadedChapters[chapterNum]) {
        const chapterToPreload = allChapters.find(ch => ch.number === chapterNum);
        if (chapterToPreload) {
          const content = await fetchChapterContent(chapterToPreload.contentURL);
          setPreloadedChapters(prev => ({...prev, [chapterNum]: content}));
        }
      }
    }
  }, [allChapters, fetchChapterContent, preloadedChapters]);

  useEffect(() => {
    const fetchData = async () => {
      // Skip if we've already loaded this chapter
      if (initialLoadComplete.current && currentChapterRef.current === chapterNumber) {
        return;
      }

      setIsLoading(true);
      try {
        const novelData = novelDetails[slug] ? novelDetails[slug] : await fetchNovelDetails(slug);
        setNovel(novelData);

        const [chaptersResponse, chapterResponse] = await Promise.all([
          supabase.from('chapters').select('id, number, title').eq('novelId', novelData.id).order('number'),
          supabase.from('chapters').select('*').eq('novelId', novelData.id).eq('number', chapterNumber).single(),
        ]);

        if (chaptersResponse.error) throw chaptersResponse.error;
        if (chapterResponse.error) throw chapterResponse.error;

        const content = await fetchChapterContent(chapterResponse.data.contentURL);

        setAllChapters(chaptersResponse.data);
        setChapterIndex(buildChapterIndex(chaptersResponse.data));
        setChapterData({
          ...chapterResponse.data,
          content
        });

        if (user) {
          const userRatingData = await fetchUserRating(novelData.id, user.id);
          setUserRating(userRatingData);
        }

        initialLoadComplete.current = true;
        currentChapterRef.current = chapterNumber;
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError('Failed to load chapter content. Please try again later.');
      } finally {
        setIsLoading(false);
        setIsFirstLoad(false);
      }
    };

    if (slug && chapterNumber) {
      fetchData();
    }
  }, [slug, chapterNumber, novelDetails, fetchNovelDetails, user, fetchChapterContent]);

  useEffect(() => {
    if (chapterData) {
      preloadAdjacentChapters(chapterData.number);
    }
  }, [chapterData, preloadAdjacentChapters]);

  const handleChapterChange = useCallback(
    async (selectedChapterNumber) => {
      setIsNavigating(true);
      setLoadingProgress(0);
      
      const intervalId = setInterval(() => {
        setLoadingProgress(prev => Math.min(prev + 10, 90));
      }, 500);

      navigate(`/novel/${slug}/chapter/${selectedChapterNumber}`);

      if (preloadedChapters[selectedChapterNumber]) {
        setChapterData(prevData => ({
          ...prevData,
          number: selectedChapterNumber,
          content: preloadedChapters[selectedChapterNumber]
        }));
        setIsNavigating(false);
        setLoadingProgress(100);
        clearInterval(intervalId);
      } else {
        try {
          const chapterResponse = await supabase
            .from('chapters')
            .select('*')
            .eq('novelId', novel.id)
            .eq('number', selectedChapterNumber)
            .single();

          if (chapterResponse.error) throw chapterResponse.error;

          const content = await fetchChapterContent(chapterResponse.data.contentURL);
          
          setChapterData({
            ...chapterResponse.data,
            content
          });
        } catch (error) {
          console.error('Failed to fetch chapter:', error);
          setError('Failed to load chapter. Please try again.');
        } finally {
          setIsNavigating(false);
          setLoadingProgress(100);
          clearInterval(intervalId);
        }
      }
    },
    [slug, navigate, preloadedChapters, fetchChapterContent, novel]
  );

  const topNavigationButtons = useMemo(() => {
    if (!chapterData || !chapterIndex.length) return [];
    return getNavigationButtons(chapterData, chapterIndex, true, slug);
  }, [chapterData, chapterIndex, slug]);
  
  const bottomNavigationButtons = useMemo(() => {
    if (!chapterData || !chapterIndex.length) return [];
    return getNavigationButtons(chapterData, chapterIndex, false, slug);
  }, [chapterData, chapterIndex, slug]);

  if (isFirstLoad) {
    return <LoadingSpinner />;
  }

  if (!novel || !chapterData) {
    return <SkeletonScreen />;
  }

  return (
    <div className="chapter-page">
      <HelmetTags />
      
      {isNavigating && <ProgressBar progress={loadingProgress} />}
      <ScrollArrows contentRef={chapterContentRef} />

      <header className="chapter-header">
        <Link to={`/novel/${slug}`} className="book-title-link">
          <h1 className="book-title">{novel.title}</h1>
        </Link>
        <div className="chapter-nav">
          <div className="chapter-select">
            <select
              className="chapter-dropdown"
              value={chapterData.number}
              onChange={(e) => handleChapterChange(e.target.value)}
              aria-label="Select Chapter"
            >
              {allChapters.map((ch) => (
                <option key={ch.number} value={ch.number} title={ch.title}>
                  {getFormattedChapterTitle(ch)}
                </option>
              ))}
            </select>
          </div>
          
          <div className="top-nav-buttons">
            {topNavigationButtons.map((button) => (
              <Link
                key={button.type}
                to={button.path}
                className={`nav-button ${button.type}`}
              >
                {button.icon}
                <span>{button.label}</span>
              </Link>
            ))}
          </div>
        </div>
      </header>

      <main className="chapter-content" ref={chapterContentRef}>
        {isNavigating ? (
          <SkeletonScreen />
        ) : isLoading ? (
          <LoadingSpinner />
        ) : (
          <ChapterContent
            chapterData={chapterData}
            isLoading={isLoading}
            isFirstLoad={isFirstLoad}
            error={error}
          />
        )}
      </main>

      <div className="bottom-nav">
        <div className="chapter-select">
          <select
            className="chapter-dropdown"
            value={chapterData.number}
            onChange={(e) => handleChapterChange(e.target.value)}
            aria-label="Select Chapter"
          >
            {allChapters.map((ch) => (
              <option key={ch.number} value={ch.number}>
                {getFormattedChapterTitle(ch)}
              </option>
            ))}
          </select>
        </div>
        {bottomNavigationButtons.map((button) => (
          <Link
            key={button.type}
            to={button.path}
            className={`nav-button ${button.type}`}
          >
            {button.icon}
            <span>{button.label}</span>
          </Link>
        ))}
      </div>

      <div className="rating-section">
        <Suspense fallback={<div>Loading Rating...</div>}>
          <StarRating
            itemId={novel.id}
            itemType="novel"
            isAuthenticated={!!user}
            userId={user?.id}
            openLoginModal={openLoginModal}
            userHasRated={userRating.userHasRated}
            currentRating={userRating.rating}
          />
        </Suspense>
      </div>
      <div className="comment-section">
        <CommentSection novelId={slug} />

      </div>
    </div>
  );
}

export default React.memo(ChapterPage);