import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export const useSEO = ({
  title = 'Chaeknovels TRANSLATED | Korean Romance Novels in English',
  description = 'Read the latest Korean novels in English translation. Updated regularly with new chapters and series.',
  type = 'website',
  imageUrl = 'https://chaeknovels.com/og-image.png', // Website logo or OpenGraph image
  structuredData = null,
  keywords = 'Lee Seob\'s Love, I Can\'t Wait to Eat You, Korean web novels, English translations, Chaeknovels, romance stories',
  author = 'Chaeknovels TRANSLATED'
}) => {
  const location = useLocation();
  const canonicalUrl = `https://chaeknovels.com${location.pathname}`;

  const HelmetTags = () => (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />

      {/* OpenGraph (OG) Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content={type} />
      <meta property="og:site_name" content="Chaeknovels TRANSLATED" />
      <meta property="og:locale" content="en_US" />

      {/* Twitter Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />

      {/* Additional Meta Tags */}
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      <meta name="robots" content="index, follow" />

      {/* Structured Data */}
      {structuredData && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      )}
    </Helmet>
  );

  return { HelmetTags };
};
