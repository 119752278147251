import DOMPurify from 'dompurify';

export const cleanHTMLContent = (htmlContent) => {
  // Convert special characters to their proper representation
  htmlContent = htmlContent
    .replace(/â€™/g, "'")
    .replace(/â€“/g, "–")
    .replace(/â€œ/g, '“')
    .replace(/â€/g, '”');

  // Remove unnecessary MS Word-specific comments, tags, and attributes
  htmlContent = htmlContent
    .replace(/<!--[\s\S]*?-->/g, '') // Remove HTML comments
    .replace(/<meta[^>]+>/gi, '') // Remove meta tags
    .replace(/<\/?o:[^>]*>/g, '') // Remove <o:p> and other o: tags
    .replace(/style=['"][^'"]*mso[^'"]*['"]/gi, '') // Remove inline MS Word styles
    .replace(/class=['"][^'"]*Mso[^'"]*['"]/gi, '') // Remove MS Word classes
    .replace(/<style[^>]*>[\s\S]*?<\/style>/gi, ''); // Remove embedded styles

  // Use DOMPurify to sanitize and allow only necessary tags and attributes
  return DOMPurify.sanitize(htmlContent, {
    ALLOWED_TAGS: ['b', 'strong', 'i', 'em', 'p', 'br', 'ul', 'ol', 'li', 'a', 'span', 'div', 'h1', 'h2', 'h3', 'h4', 'h5'], // Added headers if needed
    ALLOWED_ATTR: ['href', 'class'], // Allow 'class' for styling
  });
};
